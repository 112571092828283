var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"formRules"},[_c('div',[_c('b-card',[_c('b-card-header',[_c('b-row',{staticClass:"w-100",attrs:{"no-gutters":""}},[_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"d-flex justify-content-end"},[_c('div',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}},{name:"b-popover",rawName:"v-b-popover.hover.focus",value:(
                      '⚪️ La fiche d\'enquête sur les visiteurs internationaux - frontieres (tourisme emetteurs) est éditable par le grand public et les agents annexes. ⚪️ Il permet de collecter les données sur les voyageurs et leur séjours. ⚪️ Le remplissage de la fiche ne nécessite pas une connexion ou la création d\'un compte.'
                    ),expression:"\n                      '⚪️ La fiche d\\'enquête sur les visiteurs internationaux - frontieres (tourisme emetteurs) est éditable par le grand public et les agents annexes. ⚪️ Il permet de collecter les données sur les voyageurs et leur séjours. ⚪️ Le remplissage de la fiche ne nécessite pas une connexion ou la création d\\'un compte.'\n                    ",modifiers:{"hover":true,"focus":true}}],staticClass:"btn-icon rounded-circle",attrs:{"size":"lg","variant":"outline-primary","title":"Informations"}},[_c('feather-icon',{attrs:{"icon":"BellIcon"}})],1)],1)])]),_c('b-col',{attrs:{"md":"2"}},[_c('b-card-img',{staticClass:"rounded-0 my-2",attrs:{"src":require('@/assets/images/illustration/Globalization-pana.png'),"alt":"Image"}})],1),_c('b-col',{staticClass:"d-flex align-items-center justify-content-center",attrs:{"md":"10"}},[_c('div',[_c('h1',{staticClass:"m-auto text-center"},[_vm._v(" FICHE D'ENQUETE SUR LES VISITEURS INTERNATIONAUX - FRONTIERES ")]),_c('h1',{staticClass:"m-auto text-center"},[_vm._v("TOURISME EMETTEURS")])])])],1)],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"pb-2",attrs:{"lg":"12"}},[_c('div',[_c('b-card',[(_vm.sendForm.pays_residence != 'Benin')?_c('span',[_c('b-row',[_c('b-col',{staticClass:"mt-2",attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-cols-lg":"2","label":"Dans quel pays vivez-vous ?","label-for":"paysResidence"}},[_c('v-select',{attrs:{"type":"text","options":_vm.paysResidence,"label":"title","placeholder":"Bénin"},model:{value:(_vm.sendForm.paysResidence),callback:function ($$v) {_vm.$set(_vm.sendForm, "paysResidence", $$v)},expression:"sendForm.paysResidence"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,2975455177)})],1)],1),(_vm.sendForm.paysResidence == 'Bénin')?_c('div',[_c('b-row',[_c('b-col',{staticClass:"mt-2",attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-lg":"2","label":"Quels pays avez-vous visité(s) ?","label-for":"paysVisite"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-select',{attrs:{"type":"text","options":_vm.paysResidence,"multiple":"","label":"title","placeholder":"Japon, Brésil"},model:{value:(_vm.sendForm.paysVisites),callback:function ($$v) {_vm.$set(_vm.sendForm, "paysVisites", $$v)},expression:"sendForm.paysVisites"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1062429034)})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mt-2",attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-lg":"2","label":"De quelle nationalité êtes-vous ?","label-for":"nationality"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-select',{attrs:{"type":"text","options":_vm.paysResidence,"label":"title","placeholder":"Choisissez votre nationalité"},model:{value:(_vm.sendForm.nationalite),callback:function ($$v) {_vm.$set(_vm.sendForm, "nationalite", $$v)},expression:"sendForm.nationalite"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3212502711)})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mt-2",attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-cols-lg":"2","label":"À quelle date avez-vous quitté le Bénin ?","label-for":"date"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"placeholder":"2022-09-10","value":"date","type":"date"},model:{value:(_vm.sendForm.dateDepartBenin),callback:function ($$v) {_vm.$set(_vm.sendForm, "dateDepartBenin", $$v)},expression:"sendForm.dateDepartBenin"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,1599147617)})],1)],1),_c('b-row',[_c('b-col',{staticClass:"mt-2",attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-cols-lg":"2","label":"À quelle date êtes-vous retournés au Bénin ?","label-for":"date"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"placeholder":"2022-10-10","value":"date","type":"date"},model:{value:(_vm.sendForm.dateRetourtBenin),callback:function ($$v) {_vm.$set(_vm.sendForm, "dateRetourtBenin", $$v)},expression:"sendForm.dateRetourtBenin"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,33350630)})],1)],1),_c('b-row',[_c('b-col',{staticClass:"mt-2",attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-lg":"2","label":"Avec combien de personnes, y compris vous-même, voyagez-vous en partageant les dépenses ?","label-for":"nationality"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('span',{staticClass:"d-flex"},[_c('b-form-input',{attrs:{"placeholder":"Nombre de personnes","type":"number","min":"0"},model:{value:(_vm.sendForm.coVoyageursAdultes),callback:function ($$v) {_vm.$set(_vm.sendForm, "coVoyageursAdultes", _vm._n($$v))},expression:"sendForm.coVoyageursAdultes"}}),_c('b-form-input',{attrs:{"placeholder":"Nombre d'enfants de moins de 18 ans","type":"number","min":"0"},model:{value:(_vm.sendForm.coVoyageursEnfants),callback:function ($$v) {_vm.$set(_vm.sendForm, "coVoyageursEnfants", _vm._n($$v))},expression:"sendForm.coVoyageursEnfants"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,79925902)})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mt-2",attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-lg":"2","label-size":"lg","label":"Quel a été  le motif de votre visite à l'étranger ?","label-for":"motif_travel"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-radio-group',{attrs:{"options":_vm.motif_travel,"value-field":"id","text-field":"name","name":"some-radio9","stacked":""},model:{value:(_vm.sendForm.motifVoyage),callback:function ($$v) {_vm.$set(_vm.sendForm, "motifVoyage", $$v)},expression:"sendForm.motifVoyage"}}),(_vm.sendForm.motifVoyage == 'Autres (A preciser)')?_c('b-form-input',{staticClass:"mt-2",attrs:{"placeholder":"Autres","type":"text"},model:{value:(_vm.autresmotifVoyage),callback:function ($$v) {_vm.autresmotifVoyage=$$v},expression:"autresmotifVoyage"}}):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1019143190)})],1)],1)],1),(
                    _vm.sendForm.motifVoyage !=
                    'En transit à l\'aéroport (transfert, vol de continuation vers autre pays)'
                  )?_c('div',[_c('b-row',[_c('b-col',{staticClass:"mt-2",attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-lg":"2","label-size":"lg","label":"Parmi les activités suivantes, lesquelles avez-vous pratiquées pendant votre séjour à l'étranger ? ( Plusieurs réponses si possible)","label-for":"motif_travel"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-checkbox-group',{staticClass:"demo-inline-spacing",attrs:{"id":"checkbox-group-1","options":_vm.activite_pratique,"name":"","stacked":""},model:{value:(_vm.sendForm.activiteEtranger),callback:function ($$v) {_vm.$set(_vm.sendForm, "activiteEtranger", $$v)},expression:"sendForm.activiteEtranger"}}),(
                              _vm.sendForm.activiteEtranger ==
                              'Autres (Veuillez préciser)'
                            )?_c('b-form-input',{staticClass:"mt-2",attrs:{"placeholder":"Autres","type":"text"},model:{value:(_vm.sendForm.autresActiviteEtranger),callback:function ($$v) {_vm.$set(_vm.sendForm, "autresActiviteEtranger", $$v)},expression:"sendForm.autresActiviteEtranger"}}):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2319813195)})],1)],1)],1),_c('div',{staticClass:"table-responsive text-left mt-3"},[_c('b-form-group',{attrs:{"label-cols-lg":"12","label-size":"lg","label":"Indiquez le nombre de nuits passés par type d’hébergement pendant votre séjour à l’étranger","label-for":"motif_travel"}}),_c('table',{staticClass:"table table-striped table-bordered"},[_c('thead',[_c('tr',[_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v(" Type d'hébergement ")]),_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v("Nuitées")])])]),_c('tbody',[_c('tr',[_c('th',{attrs:{"scope":"row"}},[_vm._v("1- Hôtel / pension")]),_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                _vm.sendForm.nombreNuitParType[0].nombreNuit
                              ),expression:"\n                                sendForm.nombreNuitParType[0].nombreNuit\n                              "}],attrs:{"type":"number","min":"0"},domProps:{"value":(
                                _vm.sendForm.nombreNuitParType[0].nombreNuit
                              )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.sendForm.nombreNuitParType[0], "nombreNuit", $event.target.value)}}})])]),_c('tr',[_c('th',{attrs:{"scope":"row"}},[_vm._v("2- Parents/amis")]),_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                _vm.sendForm.nombreNuitParType[1].nombreNuit
                              ),expression:"\n                                sendForm.nombreNuitParType[1].nombreNuit\n                              "}],attrs:{"type":"number","min":"0"},domProps:{"value":(
                                _vm.sendForm.nombreNuitParType[1].nombreNuit
                              )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.sendForm.nombreNuitParType[1], "nombreNuit", $event.target.value)}}})])]),_c('tr',[_c('th',{attrs:{"scope":"row"}},[_vm._v("3- Auberge de jeunesse")]),_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                _vm.sendForm.nombreNuitParType[2].nombreNuit
                              ),expression:"\n                                sendForm.nombreNuitParType[2].nombreNuit\n                              "}],attrs:{"type":"number","min":"0"},domProps:{"value":(
                                _vm.sendForm.nombreNuitParType[2].nombreNuit
                              )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.sendForm.nombreNuitParType[2], "nombreNuit", $event.target.value)}}})])]),_c('tr',[_c('th',{attrs:{"scope":"row"}},[_vm._v("4- Résidence secondaire")]),_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                _vm.sendForm.nombreNuitParType[3].nombreNuit
                              ),expression:"\n                                sendForm.nombreNuitParType[3].nombreNuit\n                              "}],attrs:{"type":"number","min":"0"},domProps:{"value":(
                                _vm.sendForm.nombreNuitParType[3].nombreNuit
                              )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.sendForm.nombreNuitParType[3], "nombreNuit", $event.target.value)}}})])]),_c('tr',[_c('th',{attrs:{"scope":"row"}},[_vm._v("5- Maison/appartement loué")]),_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                _vm.sendForm.nombreNuitParType[4].nombreNuit
                              ),expression:"\n                                sendForm.nombreNuitParType[4].nombreNuit\n                              "}],attrs:{"type":"number"},domProps:{"value":(
                                _vm.sendForm.nombreNuitParType[4].nombreNuit
                              )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.sendForm.nombreNuitParType[4], "nombreNuit", $event.target.value)}}})])]),_c('tr',[_c('th',{attrs:{"scope":"row"}},[_vm._v("6- Chambre d’hôte")]),_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                _vm.sendForm.nombreNuitParType[5].nombreNuit
                              ),expression:"\n                                sendForm.nombreNuitParType[5].nombreNuit\n                              "}],attrs:{"type":"number","min":"0"},domProps:{"value":(
                                _vm.sendForm.nombreNuitParType[5].nombreNuit
                              )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.sendForm.nombreNuitParType[5], "nombreNuit", $event.target.value)}}})])]),_c('tr',[_c('th',{attrs:{"scope":"row"}},[_vm._v(" 7- Autre type d`hébergement. Veuillez préciser "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.sendForm.nombreNuitParType[6].type),expression:"sendForm.nombreNuitParType[6].type"}],attrs:{"type":"text"},domProps:{"value":(_vm.sendForm.nombreNuitParType[6].type)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.sendForm.nombreNuitParType[6], "type", $event.target.value)}}})]),_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                _vm.sendForm.nombreNuitParType[6].nombreNuit
                              ),expression:"\n                                sendForm.nombreNuitParType[6].nombreNuit\n                              "}],attrs:{"type":"number","min":"0"},domProps:{"value":(
                                _vm.sendForm.nombreNuitParType[6].nombreNuit
                              )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.sendForm.nombreNuitParType[6], "nombreNuit", $event.target.value)}}})])])])])],1),_c('b-row',[_c('b-col',{staticClass:"mt-2",attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-lg":"2","label-size":"lg","label":"Par quel moyen de transport avez-vous quitté le Bénin ?","label-for":"moyen_transport"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('b-form-radio-group',{attrs:{"options":_vm.moyen_transport,"value-field":"id","text-field":"name","stacked":""},model:{value:(_vm.sendForm.moyenTransportDepartBenin),callback:function ($$v) {_vm.$set(_vm.sendForm, "moyenTransportDepartBenin", $$v)},expression:"sendForm.moyenTransportDepartBenin"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4102204358)})],1)],1)],1),_c('b-row',[(_vm.sendForm.moyenTransportDepartBenin == 'Avion')?_c('b-col',{staticClass:"mt-2",attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-lg":"2","label":" Si vous avez quitté par avion, veuillez préciser s'il s'agit:","label-for":"si_avion"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('b-form-radio-group',{staticClass:"demo-inline-spacing",attrs:{"value-field":"id","text-field":"name","options":_vm.si_avion,"name":"radio-validation"},model:{value:(_vm.sendForm.departBeninParAvion.type),callback:function ($$v) {_vm.$set(_vm.sendForm.departBeninParAvion, "type", $$v)},expression:"sendForm.departBeninParAvion.type"}}),(
                              _vm.sendForm.departBeninParAvion.type ==
                              'Vol commercial'
                            )?_c('b-form-input',{staticClass:"mt-2",attrs:{"placeholder":"Veuillez préciser la compagnie aérienne","type":"text"},model:{value:(_vm.sendForm.departBeninParAvion.compagnie),callback:function ($$v) {_vm.$set(_vm.sendForm.departBeninParAvion, "compagnie", $$v)},expression:"sendForm.departBeninParAvion.compagnie"}}):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3362383459)})],1)],1):_vm._e()],1),_c('b-row',[(_vm.sendForm.moyenTransportDepartBenin == 'Autobus')?_c('b-col',{staticClass:"mt-2",attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-lg":"2","label":"Si vous êtes arrivé en autobus, veuillez le préciser: ","label-for":"si_autobus"}},[_c('b-form-group',{attrs:{"label-cols-lg":"2","label":"Le nom de la compagnie de transport","label-for":"si_autobus"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-input',{staticClass:"mt-2",attrs:{"placeholder":"Veuillez préciser la compagnie aérienne","type":"text"},model:{value:(
                                _vm.sendForm.departBeninParAutobus.compagnie
                              ),callback:function ($$v) {_vm.$set(_vm.sendForm.departBeninParAutobus, "compagnie", $$v)},expression:"\n                                sendForm.departBeninParAutobus.compagnie\n                              "}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,760075138)})],1),_c('b-form-group',{attrs:{"label-cols-lg":"2","label":"La résidence de la compagnie de transport (choisir une seule réponse)","label-for":"si_autobus"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('b-form-radio-group',{staticClass:"demo-inline-spacing",attrs:{"value-field":"id","text-field":"name","options":_vm.si_autobus,"name":"radio-validation"},model:{value:(
                                _vm.sendForm.departBeninParAutobus
                                  .residenceCompagnie
                              ),callback:function ($$v) {_vm.$set(_vm.sendForm.departBeninParAutobus
                                  , "residenceCompagnie", $$v)},expression:"\n                                sendForm.departBeninParAutobus\n                                  .residenceCompagnie\n                              "}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2518718662)})],1)],1)],1):_vm._e()],1),_c('b-row',[_c('b-col',{staticClass:"mt-2",attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-lg":"2","label-size":"lg","label":"Quel moyen de transport avez-vous utilisé au Bénin ?","label-for":"moyen_transport_utilise"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                  var errors = ref.errors;
return [_c('b-form-radio-group',{attrs:{"options":_vm.moyen_transport,"value-field":"id","text-field":"name","stacked":""},model:{value:(_vm.sendForm.moyenTransportEtranger),callback:function ($$v) {_vm.$set(_vm.sendForm, "moyenTransportEtranger", $$v)},expression:"sendForm.moyenTransportEtranger"}}),(
                              _vm.sendForm.moyenTransportEtranger ==
                              'Autres (A preciser)'
                            )?_c('b-form-input',{staticClass:"mt-2",attrs:{"placeholder":"Autres","type":"text"},model:{value:(_vm.autreMoyenTransportEtranger),callback:function ($$v) {_vm.autreMoyenTransportEtranger=$$v},expression:"autreMoyenTransportEtranger"}}):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2046770984)})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mt-2",attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-lg":"2","label":"Combien avez-vous payé en tout pour ce voyage avant votre arrivée au Bénin (Par exemple voyage à forfait = transport + l'hébergement: etc...)","label-for":"cout_avant_benin"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-radio-group',{staticClass:"demo-inline-spacing",attrs:{"options":_vm.cout_avant_benin,"value-field":"id","text-field":"name","stacked":""},model:{value:(_vm.sendForm.depensesAvantDepart.type),callback:function ($$v) {_vm.$set(_vm.sendForm.depensesAvantDepart, "type", $$v)},expression:"sendForm.depensesAvantDepart.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2297388054)})],1),(
                          _vm.sendForm.depensesAvantDepart.type ==
                          'Voyage à forfait'
                        )?_c('div',{staticClass:"table-responsive text-left mt-3"},[_c('table',{staticClass:"table table-striped table-bordered"},[_c('thead',[_c('tr',[_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v("Cochez")]),_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v(" Voyage à forfait: cochez ce qui était inclus et indiquez le cout total ")])])]),_c('tbody',[_c('tr',[_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                    _vm.sendForm.depensesAvantDepart.options[0]
                                      .etat
                                  ),expression:"\n                                    sendForm.depensesAvantDepart.options[0]\n                                      .etat\n                                  "}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(
                                    _vm.sendForm.depensesAvantDepart.options[0]
                                      .etat
                                  )?_vm._i(
                                    _vm.sendForm.depensesAvantDepart.options[0]
                                      .etat
                                  ,null)>-1:(
                                    _vm.sendForm.depensesAvantDepart.options[0]
                                      .etat
                                  )},on:{"change":function($event){var $$a=
                                    _vm.sendForm.depensesAvantDepart.options[0]
                                      .etat
                                  ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.sendForm.depensesAvantDepart.options[0]
                                      , "etat", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.sendForm.depensesAvantDepart.options[0]
                                      , "etat", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.sendForm.depensesAvantDepart.options[0]
                                      , "etat", $$c)}}}})]),_c('th',{attrs:{"scope":"row"}},[_vm._v("Transport international")])]),_c('tr',[_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                    _vm.sendForm.depensesAvantDepart.options[1]
                                      .etat
                                  ),expression:"\n                                    sendForm.depensesAvantDepart.options[1]\n                                      .etat\n                                  "}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(
                                    _vm.sendForm.depensesAvantDepart.options[1]
                                      .etat
                                  )?_vm._i(
                                    _vm.sendForm.depensesAvantDepart.options[1]
                                      .etat
                                  ,null)>-1:(
                                    _vm.sendForm.depensesAvantDepart.options[1]
                                      .etat
                                  )},on:{"change":function($event){var $$a=
                                    _vm.sendForm.depensesAvantDepart.options[1]
                                      .etat
                                  ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.sendForm.depensesAvantDepart.options[1]
                                      , "etat", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.sendForm.depensesAvantDepart.options[1]
                                      , "etat", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.sendForm.depensesAvantDepart.options[1]
                                      , "etat", $$c)}}}})]),_c('th',{attrs:{"scope":"row"}},[_vm._v("Hébergement")])]),_c('tr',[_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                    _vm.sendForm.depensesAvantDepart.options[2]
                                      .etat
                                  ),expression:"\n                                    sendForm.depensesAvantDepart.options[2]\n                                      .etat\n                                  "}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(
                                    _vm.sendForm.depensesAvantDepart.options[2]
                                      .etat
                                  )?_vm._i(
                                    _vm.sendForm.depensesAvantDepart.options[2]
                                      .etat
                                  ,null)>-1:(
                                    _vm.sendForm.depensesAvantDepart.options[2]
                                      .etat
                                  )},on:{"change":function($event){var $$a=
                                    _vm.sendForm.depensesAvantDepart.options[2]
                                      .etat
                                  ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.sendForm.depensesAvantDepart.options[2]
                                      , "etat", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.sendForm.depensesAvantDepart.options[2]
                                      , "etat", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.sendForm.depensesAvantDepart.options[2]
                                      , "etat", $$c)}}}})]),_c('th',{attrs:{"scope":"row"}},[_vm._v("Repas et boissons")])]),_c('tr',[_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                    _vm.sendForm.depensesAvantDepart.options[3]
                                      .etat
                                  ),expression:"\n                                    sendForm.depensesAvantDepart.options[3]\n                                      .etat\n                                  "}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(
                                    _vm.sendForm.depensesAvantDepart.options[3]
                                      .etat
                                  )?_vm._i(
                                    _vm.sendForm.depensesAvantDepart.options[3]
                                      .etat
                                  ,null)>-1:(
                                    _vm.sendForm.depensesAvantDepart.options[3]
                                      .etat
                                  )},on:{"change":function($event){var $$a=
                                    _vm.sendForm.depensesAvantDepart.options[3]
                                      .etat
                                  ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.sendForm.depensesAvantDepart.options[3]
                                      , "etat", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.sendForm.depensesAvantDepart.options[3]
                                      , "etat", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.sendForm.depensesAvantDepart.options[3]
                                      , "etat", $$c)}}}})]),_c('th',{attrs:{"scope":"row"}},[_vm._v("Location de voiture")])]),_c('tr',[_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                    _vm.sendForm.depensesAvantDepart.options[4]
                                      .etat
                                  ),expression:"\n                                    sendForm.depensesAvantDepart.options[4]\n                                      .etat\n                                  "}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(
                                    _vm.sendForm.depensesAvantDepart.options[4]
                                      .etat
                                  )?_vm._i(
                                    _vm.sendForm.depensesAvantDepart.options[4]
                                      .etat
                                  ,null)>-1:(
                                    _vm.sendForm.depensesAvantDepart.options[4]
                                      .etat
                                  )},on:{"change":function($event){var $$a=
                                    _vm.sendForm.depensesAvantDepart.options[4]
                                      .etat
                                  ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.sendForm.depensesAvantDepart.options[4]
                                      , "etat", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.sendForm.depensesAvantDepart.options[4]
                                      , "etat", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.sendForm.depensesAvantDepart.options[4]
                                      , "etat", $$c)}}}})]),_c('th',{attrs:{"scope":"row"}},[_vm._v(" Transport local (train, bus, taxi, etc.) ")])]),_c('tr',[_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                    _vm.sendForm.depensesAvantDepart.options[5]
                                      .etat
                                  ),expression:"\n                                    sendForm.depensesAvantDepart.options[5]\n                                      .etat\n                                  "}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(
                                    _vm.sendForm.depensesAvantDepart.options[5]
                                      .etat
                                  )?_vm._i(
                                    _vm.sendForm.depensesAvantDepart.options[5]
                                      .etat
                                  ,null)>-1:(
                                    _vm.sendForm.depensesAvantDepart.options[5]
                                      .etat
                                  )},on:{"change":function($event){var $$a=
                                    _vm.sendForm.depensesAvantDepart.options[5]
                                      .etat
                                  ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.sendForm.depensesAvantDepart.options[5]
                                      , "etat", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.sendForm.depensesAvantDepart.options[5]
                                      , "etat", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.sendForm.depensesAvantDepart.options[5]
                                      , "etat", $$c)}}}})]),_c('th',{attrs:{"scope":"row"}},[_vm._v(" Activité (loisir/sport/culture, etc) ")])]),_c('tr',[_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                    _vm.sendForm.depensesAvantDepart.options[6]
                                      .etat
                                  ),expression:"\n                                    sendForm.depensesAvantDepart.options[6]\n                                      .etat\n                                  "}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(
                                    _vm.sendForm.depensesAvantDepart.options[6]
                                      .etat
                                  )?_vm._i(
                                    _vm.sendForm.depensesAvantDepart.options[6]
                                      .etat
                                  ,null)>-1:(
                                    _vm.sendForm.depensesAvantDepart.options[6]
                                      .etat
                                  )},on:{"change":function($event){var $$a=
                                    _vm.sendForm.depensesAvantDepart.options[6]
                                      .etat
                                  ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.sendForm.depensesAvantDepart.options[6]
                                      , "etat", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.sendForm.depensesAvantDepart.options[6]
                                      , "etat", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.sendForm.depensesAvantDepart.options[6]
                                      , "etat", $$c)}}}})]),_c('th',{attrs:{"scope":"row"}},[_vm._v("Autre")])]),_c('tr',[_c('th',{attrs:{"scope":"row"}},[_vm._v("Coût total")]),_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                    _vm.sendForm.depensesAvantDepart.coutTotal
                                  ),expression:"\n                                    sendForm.depensesAvantDepart.coutTotal\n                                  "}],attrs:{"type":"number","min":"0"},domProps:{"value":(
                                    _vm.sendForm.depensesAvantDepart.coutTotal
                                  )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.sendForm.depensesAvantDepart, "coutTotal", $event.target.value)}}})])]),_c('tr',[_c('th',{attrs:{"scope":"row"}},[_vm._v(" Indiquez la devise dans laquelle vous avez payé ")]),_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                    _vm.sendForm.depensesAvantDepart.devise
                                  ),expression:"\n                                    sendForm.depensesAvantDepart.devise\n                                  "}],attrs:{"type":"text"},domProps:{"value":(
                                    _vm.sendForm.depensesAvantDepart.devise
                                  )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.sendForm.depensesAvantDepart, "devise", $event.target.value)}}})])])])])]):_vm._e(),(
                          _vm.sendForm.depensesAvantDepart.type ==
                          'Voyage sans forfait'
                        )?_c('div',{staticClass:"table-responsive text-left mt-3"},[_c('table',{staticClass:"table table-striped table-bordered"},[_c('thead',[_c('tr',[_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v("Cochez")]),_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v(" Voyage à sans forfait: Vous avez acheté le voyage et l'hébergement auprès de fournisseurs différents; indiquez le montant sur les lignes correspondes ")]),_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v("Montant")])])]),_c('tbody',[_c('tr',[_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                    _vm.sendForm.depensesAvantDepart.options[0]
                                      .etat
                                  ),expression:"\n                                    sendForm.depensesAvantDepart.options[0]\n                                      .etat\n                                  "}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(
                                    _vm.sendForm.depensesAvantDepart.options[0]
                                      .etat
                                  )?_vm._i(
                                    _vm.sendForm.depensesAvantDepart.options[0]
                                      .etat
                                  ,null)>-1:(
                                    _vm.sendForm.depensesAvantDepart.options[0]
                                      .etat
                                  )},on:{"change":function($event){var $$a=
                                    _vm.sendForm.depensesAvantDepart.options[0]
                                      .etat
                                  ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.sendForm.depensesAvantDepart.options[0]
                                      , "etat", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.sendForm.depensesAvantDepart.options[0]
                                      , "etat", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.sendForm.depensesAvantDepart.options[0]
                                      , "etat", $$c)}}}})]),_c('th',{attrs:{"scope":"row"}},[_vm._v("Transport international")]),_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                    _vm.sendForm.depensesAvantDepart.options[0]
                                      .valeur
                                  ),expression:"\n                                    sendForm.depensesAvantDepart.options[0]\n                                      .valeur\n                                  "}],attrs:{"type":"number","min":"0"},domProps:{"value":(
                                    _vm.sendForm.depensesAvantDepart.options[0]
                                      .valeur
                                  )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.sendForm.depensesAvantDepart.options[0]
                                      , "valeur", $event.target.value)}}})])]),_c('tr',[_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                    _vm.sendForm.depensesAvantDepart.options[1]
                                      .etat
                                  ),expression:"\n                                    sendForm.depensesAvantDepart.options[1]\n                                      .etat\n                                  "}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(
                                    _vm.sendForm.depensesAvantDepart.options[1]
                                      .etat
                                  )?_vm._i(
                                    _vm.sendForm.depensesAvantDepart.options[1]
                                      .etat
                                  ,null)>-1:(
                                    _vm.sendForm.depensesAvantDepart.options[1]
                                      .etat
                                  )},on:{"change":function($event){var $$a=
                                    _vm.sendForm.depensesAvantDepart.options[1]
                                      .etat
                                  ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.sendForm.depensesAvantDepart.options[1]
                                      , "etat", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.sendForm.depensesAvantDepart.options[1]
                                      , "etat", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.sendForm.depensesAvantDepart.options[1]
                                      , "etat", $$c)}}}})]),_c('th',{attrs:{"scope":"row"}},[_vm._v("Hébergement")]),_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                    _vm.sendForm.depensesAvantDepart.options[1]
                                      .valeur
                                  ),expression:"\n                                    sendForm.depensesAvantDepart.options[1]\n                                      .valeur\n                                  "}],attrs:{"type":"number","min":"0"},domProps:{"value":(
                                    _vm.sendForm.depensesAvantDepart.options[1]
                                      .valeur
                                  )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.sendForm.depensesAvantDepart.options[1]
                                      , "valeur", $event.target.value)}}})])]),_c('tr',[_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                    _vm.sendForm.depensesAvantDepart.options[2]
                                      .etat
                                  ),expression:"\n                                    sendForm.depensesAvantDepart.options[2]\n                                      .etat\n                                  "}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(
                                    _vm.sendForm.depensesAvantDepart.options[2]
                                      .etat
                                  )?_vm._i(
                                    _vm.sendForm.depensesAvantDepart.options[2]
                                      .etat
                                  ,null)>-1:(
                                    _vm.sendForm.depensesAvantDepart.options[2]
                                      .etat
                                  )},on:{"change":function($event){var $$a=
                                    _vm.sendForm.depensesAvantDepart.options[2]
                                      .etat
                                  ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.sendForm.depensesAvantDepart.options[2]
                                      , "etat", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.sendForm.depensesAvantDepart.options[2]
                                      , "etat", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.sendForm.depensesAvantDepart.options[2]
                                      , "etat", $$c)}}}})]),_c('th',{attrs:{"scope":"row"}},[_vm._v("Repas et boissons")]),_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                    _vm.sendForm.depensesAvantDepart.options[2]
                                      .valeur
                                  ),expression:"\n                                    sendForm.depensesAvantDepart.options[2]\n                                      .valeur\n                                  "}],attrs:{"type":"number","min":"0"},domProps:{"value":(
                                    _vm.sendForm.depensesAvantDepart.options[2]
                                      .valeur
                                  )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.sendForm.depensesAvantDepart.options[2]
                                      , "valeur", $event.target.value)}}})])]),_c('tr',[_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                    _vm.sendForm.depensesAvantDepart.options[3]
                                      .etat
                                  ),expression:"\n                                    sendForm.depensesAvantDepart.options[3]\n                                      .etat\n                                  "}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(
                                    _vm.sendForm.depensesAvantDepart.options[3]
                                      .etat
                                  )?_vm._i(
                                    _vm.sendForm.depensesAvantDepart.options[3]
                                      .etat
                                  ,null)>-1:(
                                    _vm.sendForm.depensesAvantDepart.options[3]
                                      .etat
                                  )},on:{"change":function($event){var $$a=
                                    _vm.sendForm.depensesAvantDepart.options[3]
                                      .etat
                                  ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.sendForm.depensesAvantDepart.options[3]
                                      , "etat", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.sendForm.depensesAvantDepart.options[3]
                                      , "etat", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.sendForm.depensesAvantDepart.options[3]
                                      , "etat", $$c)}}}})]),_c('th',{attrs:{"scope":"row"}},[_vm._v("Location de voiture")]),_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                    _vm.sendForm.depensesAvantDepart.options[3]
                                      .valeur
                                  ),expression:"\n                                    sendForm.depensesAvantDepart.options[3]\n                                      .valeur\n                                  "}],attrs:{"type":"number","min":"0"},domProps:{"value":(
                                    _vm.sendForm.depensesAvantDepart.options[3]
                                      .valeur
                                  )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.sendForm.depensesAvantDepart.options[3]
                                      , "valeur", $event.target.value)}}})])]),_c('tr',[_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                    _vm.sendForm.depensesAvantDepart.options[4]
                                      .etat
                                  ),expression:"\n                                    sendForm.depensesAvantDepart.options[4]\n                                      .etat\n                                  "}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(
                                    _vm.sendForm.depensesAvantDepart.options[4]
                                      .etat
                                  )?_vm._i(
                                    _vm.sendForm.depensesAvantDepart.options[4]
                                      .etat
                                  ,null)>-1:(
                                    _vm.sendForm.depensesAvantDepart.options[4]
                                      .etat
                                  )},on:{"change":function($event){var $$a=
                                    _vm.sendForm.depensesAvantDepart.options[4]
                                      .etat
                                  ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.sendForm.depensesAvantDepart.options[4]
                                      , "etat", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.sendForm.depensesAvantDepart.options[4]
                                      , "etat", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.sendForm.depensesAvantDepart.options[4]
                                      , "etat", $$c)}}}})]),_c('th',{attrs:{"scope":"row"}},[_vm._v(" Transport local (train, bus, taxi, etc.) ")]),_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                    _vm.sendForm.depensesAvantDepart.options[4]
                                      .valeur
                                  ),expression:"\n                                    sendForm.depensesAvantDepart.options[4]\n                                      .valeur\n                                  "}],attrs:{"type":"number","min":"0"},domProps:{"value":(
                                    _vm.sendForm.depensesAvantDepart.options[4]
                                      .valeur
                                  )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.sendForm.depensesAvantDepart.options[4]
                                      , "valeur", $event.target.value)}}})])]),_c('tr',[_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                    _vm.sendForm.depensesAvantDepart.options[5]
                                      .etat
                                  ),expression:"\n                                    sendForm.depensesAvantDepart.options[5]\n                                      .etat\n                                  "}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(
                                    _vm.sendForm.depensesAvantDepart.options[5]
                                      .etat
                                  )?_vm._i(
                                    _vm.sendForm.depensesAvantDepart.options[5]
                                      .etat
                                  ,null)>-1:(
                                    _vm.sendForm.depensesAvantDepart.options[5]
                                      .etat
                                  )},on:{"change":function($event){var $$a=
                                    _vm.sendForm.depensesAvantDepart.options[5]
                                      .etat
                                  ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.sendForm.depensesAvantDepart.options[5]
                                      , "etat", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.sendForm.depensesAvantDepart.options[5]
                                      , "etat", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.sendForm.depensesAvantDepart.options[5]
                                      , "etat", $$c)}}}})]),_c('th',{attrs:{"scope":"row"}},[_vm._v(" Activité (loisir/sport/culture, etc) ")]),_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                    _vm.sendForm.depensesAvantDepart.options[5]
                                      .valeur
                                  ),expression:"\n                                    sendForm.depensesAvantDepart.options[5]\n                                      .valeur\n                                  "}],attrs:{"type":"number","min":"0"},domProps:{"value":(
                                    _vm.sendForm.depensesAvantDepart.options[5]
                                      .valeur
                                  )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.sendForm.depensesAvantDepart.options[5]
                                      , "valeur", $event.target.value)}}})])]),_c('tr',[_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                    _vm.sendForm.depensesAvantDepart.options[7]
                                      .etat
                                  ),expression:"\n                                    sendForm.depensesAvantDepart.options[7]\n                                      .etat\n                                  "}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(
                                    _vm.sendForm.depensesAvantDepart.options[7]
                                      .etat
                                  )?_vm._i(
                                    _vm.sendForm.depensesAvantDepart.options[7]
                                      .etat
                                  ,null)>-1:(
                                    _vm.sendForm.depensesAvantDepart.options[7]
                                      .etat
                                  )},on:{"change":function($event){var $$a=
                                    _vm.sendForm.depensesAvantDepart.options[7]
                                      .etat
                                  ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.sendForm.depensesAvantDepart.options[7]
                                      , "etat", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.sendForm.depensesAvantDepart.options[7]
                                      , "etat", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.sendForm.depensesAvantDepart.options[7]
                                      , "etat", $$c)}}}})]),_c('th',{attrs:{"scope":"row"}},[_vm._v("Autre")]),_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                    _vm.sendForm.depensesAvantDepart.options[7]
                                      .valeur
                                  ),expression:"\n                                    sendForm.depensesAvantDepart.options[7]\n                                      .valeur\n                                  "}],attrs:{"type":"number","min":"0"},domProps:{"value":(
                                    _vm.sendForm.depensesAvantDepart.options[7]
                                      .valeur
                                  )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.sendForm.depensesAvantDepart.options[7]
                                      , "valeur", $event.target.value)}}})])]),_c('tr',[_c('th',{attrs:{"scope":"row","colspan":"2"}},[_vm._v("Coût total")]),_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                    _vm.sendForm.depensesAvantDepart.coutTotal
                                  ),expression:"\n                                    sendForm.depensesAvantDepart.coutTotal\n                                  "}],attrs:{"type":"number","min":"0"},domProps:{"value":(
                                    _vm.sendForm.depensesAvantDepart.coutTotal
                                  )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.sendForm.depensesAvantDepart, "coutTotal", $event.target.value)}}})])]),_c('tr',[_c('th',{attrs:{"scope":"row","colspan":"2"}},[_vm._v(" Indiquez la devise dans laquelle vous avez payé ")]),_c('td',[_c('input',{attrs:{"type":"text"}})])])])])]):_vm._e()],1)],1),_c('b-row',[_c('b-col',{staticClass:"mt-2",attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-lg":"2","label":"Combien avez-vous payé en tout pour ce voyage pendant votre séjour à l'étranger ?","label-for":"cout_etranger"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                  var errors = ref.errors;
return [_c('b-form-radio-group',{staticClass:"demo-inline-spacing",attrs:{"options":_vm.cout_etranger,"value-field":"id","text-field":"name","stacked":""},model:{value:(_vm.sendForm.depensesDurantSejour.type),callback:function ($$v) {_vm.$set(_vm.sendForm.depensesDurantSejour, "type", $$v)},expression:"sendForm.depensesDurantSejour.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1620456481)})],1),(
                          _vm.sendForm.depensesDurantSejour.type ==
                          'J\'ai payé pendant mon séjour'
                        )?_c('div',{staticClass:"table-responsive text-left mt-3"},[_c('table',{staticClass:"table table-striped table-bordered"},[_c('thead',[_c('tr',[_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v(" Indiquez le montant sur les lignes correspondantes ")]),_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v(" Indiquez la devise dans laquelle vous avez payé ")])])]),_c('tbody',[_c('tr',[_c('th',{attrs:{"scope":"row"}},[_c('span',[_vm._v(" Transport international "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                      _vm.sendForm.depensesDurantSejour.options[0]
                                        .valeur
                                    ),expression:"\n                                      sendForm.depensesDurantSejour.options[0]\n                                        .valeur\n                                    "}],attrs:{"type":"number","min":"0"},domProps:{"value":(
                                      _vm.sendForm.depensesDurantSejour.options[0]
                                        .valeur
                                    )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.sendForm.depensesDurantSejour.options[0]
                                        , "valeur", $event.target.value)}}})])]),_c('td',[_c('v-select',{attrs:{"type":"text","options":_vm.devise,"label":"title","placeholder":"Devise"},model:{value:(
                                    _vm.sendForm.depensesDurantSejour.options[0]
                                      .devise
                                  ),callback:function ($$v) {_vm.$set(_vm.sendForm.depensesDurantSejour.options[0]
                                      , "devise", $$v)},expression:"\n                                    sendForm.depensesDurantSejour.options[0]\n                                      .devise\n                                  "}})],1)]),_c('tr',[_c('th',{attrs:{"scope":"row"}},[_c('span',[_vm._v(" Hébergement "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                      _vm.sendForm.depensesDurantSejour.options[1]
                                        .valeur
                                    ),expression:"\n                                      sendForm.depensesDurantSejour.options[1]\n                                        .valeur\n                                    "}],attrs:{"type":"number","min":"0"},domProps:{"value":(
                                      _vm.sendForm.depensesDurantSejour.options[1]
                                        .valeur
                                    )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.sendForm.depensesDurantSejour.options[1]
                                        , "valeur", $event.target.value)}}})])]),_c('td',[_c('v-select',{attrs:{"type":"text","options":_vm.devise,"label":"title","placeholder":"Devise"},model:{value:(
                                    _vm.sendForm.depensesDurantSejour.options[1]
                                      .devise
                                  ),callback:function ($$v) {_vm.$set(_vm.sendForm.depensesDurantSejour.options[1]
                                      , "devise", $$v)},expression:"\n                                    sendForm.depensesDurantSejour.options[1]\n                                      .devise\n                                  "}})],1)]),_c('tr',[_c('th',{attrs:{"scope":"row"}},[_c('span',[_vm._v(" Transport local (train, bus, taxi, etc) "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                      _vm.sendForm.depensesDurantSejour.options[2]
                                        .valeur
                                    ),expression:"\n                                      sendForm.depensesDurantSejour.options[2]\n                                        .valeur\n                                    "}],attrs:{"type":"number","min":"0"},domProps:{"value":(
                                      _vm.sendForm.depensesDurantSejour.options[2]
                                        .valeur
                                    )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.sendForm.depensesDurantSejour.options[2]
                                        , "valeur", $event.target.value)}}})])]),_c('td',[_c('v-select',{attrs:{"type":"text","options":_vm.devise,"label":"title","placeholder":"Devise"},model:{value:(
                                    _vm.sendForm.depensesDurantSejour.options[2]
                                      .devise
                                  ),callback:function ($$v) {_vm.$set(_vm.sendForm.depensesDurantSejour.options[2]
                                      , "devise", $$v)},expression:"\n                                    sendForm.depensesDurantSejour.options[2]\n                                      .devise\n                                  "}})],1)]),_c('tr',[_c('th',{attrs:{"scope":"row"}},[_c('span',[_vm._v(" Location de voiture "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                      _vm.sendForm.depensesDurantSejour.options[3]
                                        .valeur
                                    ),expression:"\n                                      sendForm.depensesDurantSejour.options[3]\n                                        .valeur\n                                    "}],attrs:{"type":"number","min":"0"},domProps:{"value":(
                                      _vm.sendForm.depensesDurantSejour.options[3]
                                        .valeur
                                    )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.sendForm.depensesDurantSejour.options[3]
                                        , "valeur", $event.target.value)}}})])]),_c('td',[_c('v-select',{attrs:{"type":"text","options":_vm.devise,"label":"title","placeholder":"Devise"},model:{value:(
                                    _vm.sendForm.depensesDurantSejour.options[3]
                                      .devise
                                  ),callback:function ($$v) {_vm.$set(_vm.sendForm.depensesDurantSejour.options[3]
                                      , "devise", $$v)},expression:"\n                                    sendForm.depensesDurantSejour.options[3]\n                                      .devise\n                                  "}})],1)]),_c('tr',[_c('th',{attrs:{"scope":"row"}},[_c('span',[_vm._v(" Carburant automobile "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                      _vm.sendForm.depensesDurantSejour.options[4]
                                        .valeur
                                    ),expression:"\n                                      sendForm.depensesDurantSejour.options[4]\n                                        .valeur\n                                    "}],attrs:{"type":"number","min":"0"},domProps:{"value":(
                                      _vm.sendForm.depensesDurantSejour.options[4]
                                        .valeur
                                    )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.sendForm.depensesDurantSejour.options[4]
                                        , "valeur", $event.target.value)}}})])]),_c('td',[_c('v-select',{attrs:{"type":"text","options":_vm.devise,"label":"title","placeholder":"Devise"},model:{value:(
                                    _vm.sendForm.depensesDurantSejour.options[4]
                                      .devise
                                  ),callback:function ($$v) {_vm.$set(_vm.sendForm.depensesDurantSejour.options[4]
                                      , "devise", $$v)},expression:"\n                                    sendForm.depensesDurantSejour.options[4]\n                                      .devise\n                                  "}})],1)]),_c('tr',[_c('th',{attrs:{"scope":"row"}},[_c('span',[_vm._v(" Repas et boisson "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                      _vm.sendForm.depensesDurantSejour.options[5]
                                        .valeur
                                    ),expression:"\n                                      sendForm.depensesDurantSejour.options[5]\n                                        .valeur\n                                    "}],attrs:{"type":"number","min":"0"},domProps:{"value":(
                                      _vm.sendForm.depensesDurantSejour.options[5]
                                        .valeur
                                    )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.sendForm.depensesDurantSejour.options[5]
                                        , "valeur", $event.target.value)}}})])]),_c('td',[_c('v-select',{attrs:{"type":"text","options":_vm.devise,"label":"title","placeholder":"Devise"},model:{value:(
                                    _vm.sendForm.depensesDurantSejour.options[5]
                                      .devise
                                  ),callback:function ($$v) {_vm.$set(_vm.sendForm.depensesDurantSejour.options[5]
                                      , "devise", $$v)},expression:"\n                                    sendForm.depensesDurantSejour.options[5]\n                                      .devise\n                                  "}})],1)]),_c('tr',[_c('th',{attrs:{"scope":"row"}},[_c('span',[_vm._v(" Produits culturels "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                      _vm.sendForm.depensesDurantSejour.options[6]
                                        .valeur
                                    ),expression:"\n                                      sendForm.depensesDurantSejour.options[6]\n                                        .valeur\n                                    "}],attrs:{"type":"number","min":"0"},domProps:{"value":(
                                      _vm.sendForm.depensesDurantSejour.options[6]
                                        .valeur
                                    )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.sendForm.depensesDurantSejour.options[6]
                                        , "valeur", $event.target.value)}}})])]),_c('td',[_c('v-select',{attrs:{"type":"text","options":_vm.devise,"label":"title","placeholder":"Devise"},model:{value:(
                                    _vm.sendForm.depensesDurantSejour.options[6]
                                      .devise
                                  ),callback:function ($$v) {_vm.$set(_vm.sendForm.depensesDurantSejour.options[6]
                                      , "devise", $$v)},expression:"\n                                    sendForm.depensesDurantSejour.options[6]\n                                      .devise\n                                  "}})],1)]),_c('tr',[_c('th',{attrs:{"scope":"row"}},[_c('span',[_vm._v(" Sport et produit de loisirs "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                      _vm.sendForm.depensesDurantSejour.options[7]
                                        .valeur
                                    ),expression:"\n                                      sendForm.depensesDurantSejour.options[7]\n                                        .valeur\n                                    "}],attrs:{"type":"number","min":"0"},domProps:{"value":(
                                      _vm.sendForm.depensesDurantSejour.options[7]
                                        .valeur
                                    )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.sendForm.depensesDurantSejour.options[7]
                                        , "valeur", $event.target.value)}}})])]),_c('td',[_c('v-select',{attrs:{"type":"text","options":_vm.devise,"label":"title","placeholder":"Devise"},model:{value:(
                                    _vm.sendForm.depensesDurantSejour.options[7]
                                      .devise
                                  ),callback:function ($$v) {_vm.$set(_vm.sendForm.depensesDurantSejour.options[7]
                                      , "devise", $$v)},expression:"\n                                    sendForm.depensesDurantSejour.options[7]\n                                      .devise\n                                  "}})],1)]),_c('tr',[_c('th',{attrs:{"scope":"row"}},[_c('span',[_vm._v(" Bien et services liés à la santé "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                      _vm.sendForm.depensesDurantSejour.options[8]
                                        .valeur
                                    ),expression:"\n                                      sendForm.depensesDurantSejour.options[8]\n                                        .valeur\n                                    "}],attrs:{"type":"number","min":"0"},domProps:{"value":(
                                      _vm.sendForm.depensesDurantSejour.options[8]
                                        .valeur
                                    )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.sendForm.depensesDurantSejour.options[8]
                                        , "valeur", $event.target.value)}}})])]),_c('td',[_c('v-select',{attrs:{"type":"text","options":_vm.devise,"label":"title","placeholder":"Devise"},model:{value:(
                                    _vm.sendForm.depensesDurantSejour.options[8]
                                      .devise
                                  ),callback:function ($$v) {_vm.$set(_vm.sendForm.depensesDurantSejour.options[8]
                                      , "devise", $$v)},expression:"\n                                    sendForm.depensesDurantSejour.options[8]\n                                      .devise\n                                  "}})],1)]),_c('tr',[_c('th',{attrs:{"scope":"row"}},[_c('span',[_vm._v(" Textiles, vêtements, chaussures et produits en cuire "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                      _vm.sendForm.depensesDurantSejour.options[9]
                                        .valeur
                                    ),expression:"\n                                      sendForm.depensesDurantSejour.options[9]\n                                        .valeur\n                                    "}],attrs:{"type":"number","min":"0"},domProps:{"value":(
                                      _vm.sendForm.depensesDurantSejour.options[9]
                                        .valeur
                                    )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.sendForm.depensesDurantSejour.options[9]
                                        , "valeur", $event.target.value)}}})])]),_c('td',[_c('v-select',{attrs:{"type":"text","options":_vm.devise,"label":"title","placeholder":"Devise"},model:{value:(
                                    _vm.sendForm.depensesDurantSejour.options[9]
                                      .devise
                                  ),callback:function ($$v) {_vm.$set(_vm.sendForm.depensesDurantSejour.options[9]
                                      , "devise", $$v)},expression:"\n                                    sendForm.depensesDurantSejour.options[9]\n                                      .devise\n                                  "}})],1)]),_c('tr',[_c('th',{attrs:{"scope":"row"}},[_c('span',[_vm._v(" Tabac "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                      _vm.sendForm.depensesDurantSejour
                                        .options[10].valeur
                                    ),expression:"\n                                      sendForm.depensesDurantSejour\n                                        .options[10].valeur\n                                    "}],attrs:{"type":"number","min":"0"},domProps:{"value":(
                                      _vm.sendForm.depensesDurantSejour
                                        .options[10].valeur
                                    )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.sendForm.depensesDurantSejour
                                        .options[10], "valeur", $event.target.value)}}})])]),_c('td',[_c('v-select',{attrs:{"type":"text","options":_vm.devise,"label":"title","placeholder":"Devise"},model:{value:(
                                    _vm.sendForm.depensesDurantSejour.options[10]
                                      .devise
                                  ),callback:function ($$v) {_vm.$set(_vm.sendForm.depensesDurantSejour.options[10]
                                      , "devise", $$v)},expression:"\n                                    sendForm.depensesDurantSejour.options[10]\n                                      .devise\n                                  "}})],1)]),_c('tr',[_c('th',{attrs:{"scope":"row"}},[_c('span',[_vm._v(" Objets de valeurs "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                      _vm.sendForm.depensesDurantSejour
                                        .options[11].valeur
                                    ),expression:"\n                                      sendForm.depensesDurantSejour\n                                        .options[11].valeur\n                                    "}],attrs:{"type":"number","min":"0"},domProps:{"value":(
                                      _vm.sendForm.depensesDurantSejour
                                        .options[11].valeur
                                    )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.sendForm.depensesDurantSejour
                                        .options[11], "valeur", $event.target.value)}}})])]),_c('td',[_c('v-select',{attrs:{"type":"text","options":_vm.devise,"label":"title","placeholder":"Devise"},model:{value:(
                                    _vm.sendForm.depensesDurantSejour.options[11]
                                      .devise
                                  ),callback:function ($$v) {_vm.$set(_vm.sendForm.depensesDurantSejour.options[11]
                                      , "devise", $$v)},expression:"\n                                    sendForm.depensesDurantSejour.options[11]\n                                      .devise\n                                  "}})],1)]),_c('tr',[_c('th',{attrs:{"scope":"row"}},[_c('span',[_vm._v(" Autres "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                      _vm.sendForm.depensesDurantSejour
                                        .options[12].valeur
                                    ),expression:"\n                                      sendForm.depensesDurantSejour\n                                        .options[12].valeur\n                                    "}],attrs:{"type":"number","min":"0"},domProps:{"value":(
                                      _vm.sendForm.depensesDurantSejour
                                        .options[12].valeur
                                    )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.sendForm.depensesDurantSejour
                                        .options[12], "valeur", $event.target.value)}}})])]),_c('td',[_c('v-select',{attrs:{"type":"text","options":_vm.devise,"label":"title","placeholder":"Devise"},model:{value:(
                                    _vm.sendForm.depensesDurantSejour.options[12]
                                      .devise
                                  ),callback:function ($$v) {_vm.$set(_vm.sendForm.depensesDurantSejour.options[12]
                                      , "devise", $$v)},expression:"\n                                    sendForm.depensesDurantSejour.options[12]\n                                      .devise\n                                  "}})],1)])])])]):_vm._e()],1)],1),_c('b-row',[_c('b-col',{staticClass:"mt-2",attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-lg":"2","label-size":"lg","label":"Dans l'ensemble, votre séjour à l'étranger vous a-t-il satisfait ?","label-for":"sejour_satisfaisant"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                      var errors = ref.errors;
return [_c('b-form-radio-group',{attrs:{"options":_vm.sejour_satisfaisant,"value-field":"id","text-field":"name","stacked":""},model:{value:(_vm.sendForm.satisfactionSejour),callback:function ($$v) {_vm.$set(_vm.sendForm, "satisfactionSejour", $$v)},expression:"sendForm.satisfactionSejour"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,562156800)})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mt-2",attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-lg":"2","label-size":"lg","label":"Voyagez-vous souvent à l'étranger ?","label-for":"souvent_etranger"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                      var errors = ref.errors;
return [_c('b-form-radio-group',{attrs:{"options":_vm.souvent_etranger,"value-field":"id","text-field":"name","stacked":""},model:{value:(_vm.sendForm.frequenceVoyage),callback:function ($$v) {_vm.$set(_vm.sendForm, "frequenceVoyage", $$v)},expression:"sendForm.frequenceVoyage"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1903177529)})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mt-2",attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-lg":"2","label":"Sexe","label-for":"sexe"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                      var errors = ref.errors;
return [_c('b-form-radio-group',{staticClass:"demo-inline-spacing",attrs:{"options":_vm.sexe,"name":"radio-sexe"},model:{value:(_vm.sendForm.sexe),callback:function ($$v) {_vm.$set(_vm.sendForm, "sexe", $$v)},expression:"sendForm.sexe"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2968074921)})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mt-2",attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-lg":"2","label":"Votre âge","label-for":"age"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                      var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Age","type":"number","min":"0"},model:{value:(_vm.sendForm.age),callback:function ($$v) {_vm.$set(_vm.sendForm, "age", $$v)},expression:"sendForm.age"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,234820771)})],1)],1)],1)],1):_vm._e()],1):_vm._e(),_c('div',{staticClass:"text-right"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"submit","variant":"primary","block":""},on:{"click":function($event){return _vm.sendToPreview()}}},[_vm._v(" Aperçu avant enregistrement ")])],1)],1):_vm._e()])],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }