<template>
  <div>
    <validation-observer ref="formRules">
      <!-- head -->
      <div>
        <!-- <b-row> {{infos}}</b-row> -->
        <b-card>
          <b-card-header>
            <b-row no-gutters class="w-100">
              <b-col md="12">
                <div class="d-flex justify-content-end">
                  <div>
                    <b-button
                      size="lg"
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                      class="btn-icon rounded-circle"
                      title="Informations"
                      v-b-popover.hover.focus="
                        '⚪️ La fiche d\'enquête sur les visiteurs internationaux - frontieres (tourisme emetteurs) est éditable par le grand public et les agents annexes. ⚪️ Il permet de collecter les données sur les voyageurs et leur séjours. ⚪️ Le remplissage de la fiche ne nécessite pas une connexion ou la création d\'un compte.'
                      "
                    >
                      <feather-icon icon="BellIcon" />
                    </b-button>
                  </div>
                </div>
              </b-col>
              <b-col md="2">
                <b-card-img
                  :src="
                    require('@/assets/images/illustration/Globalization-pana.png')
                  "
                  alt="Image"
                  class="rounded-0 my-2"
                >
                </b-card-img>
              </b-col>
              <b-col
                md="10"
                class="d-flex align-items-center justify-content-center"
              >
                <div>
                  <h1 class="m-auto text-center">
                    FICHE D'ENQUETE SUR LES VISITEURS INTERNATIONAUX -
                    FRONTIERES
                  </h1>
                  <h1 class="m-auto text-center">TOURISME EMETTEURS</h1>
                  <!-- <br /> -->
                  <!-- <h4 class="m-auto text-center">
                    Location de véhicules sans opérateur
                  </h4> -->
                  <!-- <h4 class="m-auto text-center uppercase">
                    Enquête sur les visiteurs internationaux – frontières
                    (Tourisme récepteur)
                  </h4> -->
                </div>
              </b-col>
            </b-row>
          </b-card-header>

          <!-- <content-loader>
            <circle
              cx="29"
              cy="30"
              r="17"
            />
            <rect
              x="58"
              y="18"
              rx="2"
              ry="2"
              width="140"
              height="10"
            />
            <rect
              x="58"
              y="34"
              rx="2"
              ry="2"
              width="140"
              height="10"
            />
            <rect
              x="-7"
              y="60"
              rx="2"
              ry="2"
              width="408"
              height="132"
          />
        </content-loader> -->
        </b-card>
      </div>
      <b-row>
        <!-- navigation -->
        <!-- <b-col lg="3" class="d-none d-lg-block">
          <div>
            <b-card>
              <b-list-group>
                <b-list-group-item href="#identification">
                  IDENTIFICATION
                </b-list-group-item>
                <b-list-group-item href="#classification">
                  CLASSIFICATION DES VOYAGES ET/OU EXCURSIONS
                </b-list-group-item>
                <b-list-group-item href="#voyagesBenin">
                  DETAILS SUR LE VOYAGE ET LES DEPENSES AU BENIN
                </b-list-group-item>
                <b-list-group-item href="#voyagesEtranger">
                  DETAILS SUR LE VOYAGE ET LES DEPENSES A L'ETRANGER
                </b-list-group-item>
                <b-list-group-item href="#excursionsBenin">
                  EXCURSIONS AU BENIN
                </b-list-group-item>
                <b-list-group-item href="#excursionsEtranger">
                  EXCURSIONS A L'ETRANGER
                </b-list-group-item>
              </b-list-group>
            </b-card>
          </div>
          <div class="text-center">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              block
              @click="sendToPreview()"
            >
              Aperçu avant enregistrement
            </b-button>
          </div>
        </b-col> -->
        <!-- body -->
        <b-col lg="12" class="pb-2">
          <div>
            <b-card>
              <span v-if="sendForm.pays_residence != 'Benin'">
                <!-- Pays de résidence -->
                <b-row>
                  <b-col md="12" class="mt-2">
                    <validation-provider #default="{ errors }" rules="required">
                      <b-form-group
                        label-cols-lg="2"
                        label="Dans quel pays vivez-vous ?"
                        label-for="paysResidence"
                      >
                        <v-select
                          v-model="sendForm.paysResidence"
                          type="text"
                          :options="paysResidence"
                          label="title"
                          placeholder="Bénin"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>

                <div v-if="sendForm.paysResidence == 'Bénin'">
                  <!-- Pays visité -->
                  <b-row>
                    <b-col md="12" class="mt-2">
                      <b-form-group
                        label-cols-lg="2"
                        label="Quels pays avez-vous visité(s) ?"
                        label-for="paysVisite"
                      >
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                        >
                          <v-select
                            v-model="sendForm.paysVisites"
                            type="text"
                            :options="paysResidence"
                            multiple
                            label="title"
                            placeholder="Japon, Brésil"
                          />
                          <!-- <b-form-input
                          v-model="sendForm.paysVisites"
                          placeholder="Quel(s) est(sontS) le(s) pays que vous avez visité(s)"
                          type="text"
                        /> -->
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <!-- Nationalité  -->
                  <b-row>
                    <b-col md="12" class="mt-2">
                      <b-form-group
                        label-cols-lg="2"
                        label="De quelle nationalité êtes-vous ?"
                        label-for="nationality"
                      >
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                        >
                          <v-select
                            v-model="sendForm.nationalite"
                            type="text"
                            :options="paysResidence"
                            label="title"
                            placeholder="Choisissez votre nationalité"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <!-- Date de départ -->

                  <b-row>
                    <b-col md="12" class="mt-2">
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-group
                          label-cols-lg="2"
                          label="À quelle date avez-vous quitté le Bénin ?"
                          label-for="date"
                        >
                          <flat-pickr
                            v-model="sendForm.dateDepartBenin"
                            class="form-control"
                            placeholder="2022-09-10"
                            value="date"
                            type="date"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>

                  <!-- Date de retour -->
                  <b-row>
                    <b-col md="12" class="mt-2">
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-group
                          label-cols-lg="2"
                          label="À quelle date êtes-vous retournés au Bénin ?"
                          label-for="date"
                        >
                          <flat-pickr
                            v-model="sendForm.dateRetourtBenin"
                            class="form-control"
                            placeholder="2022-10-10"
                            value="date"
                            type="date"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>

                  <!-- Nombre de personnes avec qui vous voyagez -->
                  <b-row>
                    <b-col md="12" class="mt-2">
                      <b-form-group
                        label-cols-lg="2"
                        label="Avec combien de personnes, y compris vous-même, voyagez-vous en partageant les dépenses ?"
                        label-for="nationality"
                      >
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                        >
                          <span class="d-flex">
                            <b-form-input
                              v-model.number="sendForm.coVoyageursAdultes"
                              placeholder="Nombre de personnes"
                              type="number"
                              min="0"
                            />
                            <b-form-input
                              v-model.number="sendForm.coVoyageursEnfants"
                              placeholder="Nombre d'enfants de moins de 18 ans"
                              type="number"
                              min="0"
                            />
                          </span>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <!-- Motif de visite à l'étranger -->
                  <b-row>
                    <b-col md="12" class="mt-2">
                      <b-form-group
                        label-cols-lg="2"
                        label-size="lg"
                        label="Quel a été  le motif de votre visite à l'étranger ?"
                        label-for="motif_travel"
                      >
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                        >
                          <b-form-radio-group
                            v-model="sendForm.motifVoyage"
                            :options="motif_travel"
                            value-field="id"
                            text-field="name"
                            name="some-radio9"
                            stacked
                          />
                          <!-- class="demo-inline-spacing control-label col-sm-3" -->
                          <b-form-input
                            v-if="sendForm.motifVoyage == 'Autres (A preciser)'"
                            v-model="autresmotifVoyage"
                            placeholder="Autres"
                            class="mt-2"
                            type="text"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <!-- {{ sendForm.motifVoyageEtranger }} -->

                  <div
                    v-if="
                      sendForm.motifVoyage !=
                      'En transit à l\'aéroport (transfert, vol de continuation vers autre pays)'
                    "
                  >
                    <!-- Activité pratiquée  -->
                    <b-row>
                      <b-col md="12" class="mt-2">
                        <b-form-group
                          label-cols-lg="2"
                          label-size="lg"
                          label="Parmi les activités suivantes, lesquelles avez-vous pratiquées pendant votre séjour à l'étranger ? ( Plusieurs réponses si possible)"
                          label-for="motif_travel"
                        >
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                          >
                            <!-- <b-form-radio-group
                            v-model="sendForm.activiteEtranger"
                            :options="activite_pratique"
                            value-field="id"
                            text-field="name"
                            name="some-radio9"
                            stacked
                          /> -->

                            <b-form-checkbox-group
                              id="checkbox-group-1"
                              v-model="sendForm.activiteEtranger"
                              :options="activite_pratique"
                              name=""
                              class="demo-inline-spacing"
                              stacked
                            />

                            <!-- class="demo-inline-spacing control-label col-sm-3" -->
                            <b-form-input
                              v-if="
                                sendForm.activiteEtranger ==
                                'Autres (Veuillez préciser)'
                              "
                              v-model="sendForm.autresActiviteEtranger"
                              placeholder="Autres"
                              class="mt-2"
                              type="text"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <!-- Nuits passées -->
                    <div class="table-responsive text-left mt-3">
                      <b-form-group
                        label-cols-lg="12"
                        label-size="lg"
                        label="Indiquez le nombre de nuits passés par type d’hébergement pendant votre séjour à l’étranger"
                        label-for="motif_travel"
                      />
                      <table class="table table-striped table-bordered">
                        <thead>
                          <tr>
                            <th scope="col" class="text-left">
                              Type d'hébergement
                            </th>
                            <th scope="col" class="text-left">Nuitées</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th scope="row">1- Hôtel / pension</th>
                            <td>
                              <input
                                type="number"
                                min="0"
                                v-model="
                                  sendForm.nombreNuitParType[0].nombreNuit
                                "
                              />
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">2- Parents/amis</th>
                            <td>
                              <input
                                type="number"
                                min="0"
                                v-model="
                                  sendForm.nombreNuitParType[1].nombreNuit
                                "
                              />
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">3- Auberge de jeunesse</th>
                            <td>
                              <input
                                type="number"
                                min="0"
                                v-model="
                                  sendForm.nombreNuitParType[2].nombreNuit
                                "
                              />
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">4- Résidence secondaire</th>
                            <td>
                              <input
                                type="number"
                                min="0"
                                v-model="
                                  sendForm.nombreNuitParType[3].nombreNuit
                                "
                              />
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">5- Maison/appartement loué</th>
                            <td>
                              <input
                                type="number"
                                v-model="
                                  sendForm.nombreNuitParType[4].nombreNuit
                                "
                              />
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">6- Chambre d’hôte</th>
                            <td>
                              <input
                                type="number"
                                min="0"
                                v-model="
                                  sendForm.nombreNuitParType[5].nombreNuit
                                "
                              />
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">
                              7- Autre type d`hébergement. Veuillez préciser
                              <input
                                type="text"
                                v-model="sendForm.nombreNuitParType[6].type"
                              />
                            </th>
                            <td>
                              <input
                                type="number"
                                min="0"
                                v-model="
                                  sendForm.nombreNuitParType[6].nombreNuit
                                "
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <!-- moyen de transport pour quitter le bénin -->
                    <b-row>
                      <b-col md="12" class="mt-2">
                        <!-- Moyen transport pour quitter bénin -->
                        <!-- {{sendForm.moyenTransportDepartBenin}} -->
                        <b-form-group
                          label-cols-lg="2"
                          label-size="lg"
                          label="Par quel moyen de transport avez-vous quitté le Bénin ?"
                          label-for="moyen_transport"
                        >
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                          >
                            <b-form-radio-group
                              v-model="sendForm.moyenTransportDepartBenin"
                              :options="moyen_transport"
                              value-field="id"
                              text-field="name"
                              stacked
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <!-- Si avion  -->
                    <b-row>
                      <b-col
                        md="12"
                        class="mt-2"
                        v-if="sendForm.moyenTransportDepartBenin == 'Avion'"
                      >
                        <b-form-group
                          label-cols-lg="2"
                          label=" Si vous avez quitté par avion, veuillez préciser s'il s'agit:"
                          label-for="si_avion"
                        >
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                          >
                            <b-form-radio-group
                              v-model="sendForm.departBeninParAvion.type"
                              value-field="id"
                              text-field="name"
                              :options="si_avion"
                              class="demo-inline-spacing"
                              name="radio-validation"
                            />
                            <b-form-input
                              v-if="
                                sendForm.departBeninParAvion.type ==
                                'Vol commercial'
                              "
                              v-model="sendForm.departBeninParAvion.compagnie"
                              placeholder="Veuillez préciser la compagnie aérienne"
                              class="mt-2"
                              type="text"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <!-- Si autoaubus -->
                    <b-row>
                      <b-col
                        md="12"
                        class="mt-2"
                        v-if="sendForm.moyenTransportDepartBenin == 'Autobus'"
                      >
                        <b-form-group
                          label-cols-lg="2"
                          label="Si vous êtes arrivé en autobus, veuillez le préciser: "
                          label-for="si_autobus"
                        >
                          <b-form-group
                            label-cols-lg="2"
                            label="Le nom de la compagnie de transport"
                            label-for="si_autobus"
                          >
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-input
                                v-model="
                                  sendForm.departBeninParAutobus.compagnie
                                "
                                placeholder="Veuillez préciser la compagnie aérienne"
                                class="mt-2"
                                type="text"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>

                          <b-form-group
                            label-cols-lg="2"
                            label="La résidence de la compagnie de transport (choisir une seule réponse)"
                            label-for="si_autobus"
                          >
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-radio-group
                                v-model="
                                  sendForm.departBeninParAutobus
                                    .residenceCompagnie
                                "
                                value-field="id"
                                text-field="name"
                                :options="si_autobus"
                                class="demo-inline-spacing"
                                name="radio-validation"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <!-- Moyen transport utilisé a l'étranger -->
                    <b-row>
                      <b-col md="12" class="mt-2">
                        <!-- Moyen transport utilisé a l'étranger -->
                        <!-- {{sendForm.moyenTransportEtranger}} -->
                        <b-form-group
                          label-cols-lg="2"
                          label-size="lg"
                          label="Quel moyen de transport avez-vous utilisé au Bénin ?"
                          label-for="moyen_transport_utilise"
                        >
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                          >
                            <b-form-radio-group
                              v-model="sendForm.moyenTransportEtranger"
                              :options="moyen_transport"
                              value-field="id"
                              text-field="name"
                              stacked
                            />
                            <!-- class="demo-inline-spacing control-label col-sm-3" -->
                            <b-form-input
                              v-if="
                                sendForm.moyenTransportEtranger ==
                                'Autres (A preciser)'
                              "
                              v-model="autreMoyenTransportEtranger"
                              placeholder="Autres"
                              class="mt-2"
                              type="text"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <!-- Cout avant arrivé Bénin  -->
                    <b-row>
                      <b-col md="12" class="mt-2">
                        <b-form-group
                          label-cols-lg="2"
                          label="Combien avez-vous payé en tout pour ce voyage avant votre arrivée au Bénin (Par exemple voyage à forfait = transport + l'hébergement: etc...)"
                          label-for="cout_avant_benin"
                        >
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                          >
                            <b-form-radio-group
                              v-model="sendForm.depensesAvantDepart.type"
                              :options="cout_avant_benin"
                              value-field="id"
                              text-field="name"
                              class="demo-inline-spacing"
                              stacked
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                        <!-- voyage à forfait -->
                        <div
                          class="table-responsive text-left mt-3"
                          v-if="
                            sendForm.depensesAvantDepart.type ==
                            'Voyage à forfait'
                          "
                        >
                          <table class="table table-striped table-bordered">
                            <thead>
                              <tr>
                                <th scope="col" class="text-left">Cochez</th>
                                <th scope="col" class="text-left">
                                  Voyage à forfait: cochez ce qui était inclus
                                  et indiquez le cout total
                                </th>
                                <!-- <th scope="col">Femmes</th> -->
                                <!-- <th scope="col">Total</th> -->
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <input
                                    type="checkbox"
                                    v-model="
                                      sendForm.depensesAvantDepart.options[0]
                                        .etat
                                    "
                                  />
                                </td>
                                <th scope="row">Transport international</th>
                              </tr>
                              <tr>
                                <td>
                                  <input
                                    type="checkbox"
                                    v-model="
                                      sendForm.depensesAvantDepart.options[1]
                                        .etat
                                    "
                                  />
                                </td>
                                <th scope="row">Hébergement</th>
                              </tr>
                              <tr>
                                <td>
                                  <input
                                    type="checkbox"
                                    v-model="
                                      sendForm.depensesAvantDepart.options[2]
                                        .etat
                                    "
                                  />
                                </td>
                                <th scope="row">Repas et boissons</th>
                              </tr>
                              <tr>
                                <td>
                                  <input
                                    type="checkbox"
                                    v-model="
                                      sendForm.depensesAvantDepart.options[3]
                                        .etat
                                    "
                                  />
                                </td>
                                <th scope="row">Location de voiture</th>
                              </tr>
                              <tr>
                                <td>
                                  <input
                                    type="checkbox"
                                    v-model="
                                      sendForm.depensesAvantDepart.options[4]
                                        .etat
                                    "
                                  />
                                </td>
                                <th scope="row">
                                  Transport local (train, bus, taxi, etc.)
                                </th>
                              </tr>
                              <tr>
                                <td>
                                  <input
                                    type="checkbox"
                                    v-model="
                                      sendForm.depensesAvantDepart.options[5]
                                        .etat
                                    "
                                  />
                                </td>
                                <th scope="row">
                                  Activité (loisir/sport/culture, etc)
                                </th>
                              </tr>
                              <tr>
                                <td>
                                  <input
                                    type="checkbox"
                                    v-model="
                                      sendForm.depensesAvantDepart.options[6]
                                        .etat
                                    "
                                  />
                                </td>
                                <th scope="row">Autre</th>
                              </tr>
                              <tr>
                                <th scope="row">Coût total</th>
                                <td>
                                  <input
                                    type="number"
                                    min="0"
                                    v-model="
                                      sendForm.depensesAvantDepart.coutTotal
                                    "
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">
                                  Indiquez la devise dans laquelle vous avez
                                  payé
                                </th>
                                <td>
                                  <input
                                    type="text"
                                    v-model="
                                      sendForm.depensesAvantDepart.devise
                                    "
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <!-- voyage sans forfait -->
                        <div
                          class="table-responsive text-left mt-3"
                          v-if="
                            sendForm.depensesAvantDepart.type ==
                            'Voyage sans forfait'
                          "
                        >
                          <table class="table table-striped table-bordered">
                            <thead>
                              <tr>
                                <th scope="col" class="text-left">Cochez</th>
                                <th scope="col" class="text-left">
                                  Voyage à sans forfait: Vous avez acheté le
                                  voyage et l'hébergement auprès de fournisseurs
                                  différents; indiquez le montant sur les lignes
                                  correspondes
                                </th>
                                <th scope="col" class="text-left">Montant</th>
                                <!-- <th scope="col">Femmes</th> -->
                                <!-- <th scope="col">Total</th> -->
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <input
                                    type="checkbox"
                                    v-model="
                                      sendForm.depensesAvantDepart.options[0]
                                        .etat
                                    "
                                  />
                                </td>
                                <th scope="row">Transport international</th>
                                <td>
                                  <input
                                    type="number"
                                    min="0"
                                    v-model="
                                      sendForm.depensesAvantDepart.options[0]
                                        .valeur
                                    "
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <input
                                    type="checkbox"
                                    v-model="
                                      sendForm.depensesAvantDepart.options[1]
                                        .etat
                                    "
                                  />
                                </td>
                                <th scope="row">Hébergement</th>
                                <td>
                                  <input
                                    type="number"
                                    min="0"
                                    v-model="
                                      sendForm.depensesAvantDepart.options[1]
                                        .valeur
                                    "
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <input
                                    type="checkbox"
                                    v-model="
                                      sendForm.depensesAvantDepart.options[2]
                                        .etat
                                    "
                                  />
                                </td>
                                <th scope="row">Repas et boissons</th>
                                <td>
                                  <input
                                    type="number"
                                    min="0"
                                    v-model="
                                      sendForm.depensesAvantDepart.options[2]
                                        .valeur
                                    "
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <input
                                    type="checkbox"
                                    v-model="
                                      sendForm.depensesAvantDepart.options[3]
                                        .etat
                                    "
                                  />
                                </td>
                                <th scope="row">Location de voiture</th>
                                <td>
                                  <input
                                    type="number"
                                    min="0"
                                    v-model="
                                      sendForm.depensesAvantDepart.options[3]
                                        .valeur
                                    "
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <input
                                    type="checkbox"
                                    v-model="
                                      sendForm.depensesAvantDepart.options[4]
                                        .etat
                                    "
                                  />
                                </td>
                                <th scope="row">
                                  Transport local (train, bus, taxi, etc.)
                                </th>
                                <td>
                                  <input
                                    type="number"
                                    min="0"
                                    v-model="
                                      sendForm.depensesAvantDepart.options[4]
                                        .valeur
                                    "
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <input
                                    type="checkbox"
                                    v-model="
                                      sendForm.depensesAvantDepart.options[5]
                                        .etat
                                    "
                                  />
                                </td>
                                <th scope="row">
                                  Activité (loisir/sport/culture, etc)
                                </th>
                                <td>
                                  <input
                                    type="number"
                                    min="0"
                                    v-model="
                                      sendForm.depensesAvantDepart.options[5]
                                        .valeur
                                    "
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <input
                                    type="checkbox"
                                    v-model="
                                      sendForm.depensesAvantDepart.options[7]
                                        .etat
                                    "
                                  />
                                </td>
                                <th scope="row">Autre</th>
                                <td>
                                  <input
                                    type="number"
                                    min="0"
                                    v-model="
                                      sendForm.depensesAvantDepart.options[7]
                                        .valeur
                                    "
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row" colspan="2">Coût total</th>
                                <td>
                                  <input
                                    type="number"
                                    min="0"
                                    v-model="
                                      sendForm.depensesAvantDepart.coutTotal
                                    "
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row" colspan="2">
                                  Indiquez la devise dans laquelle vous avez
                                  payé
                                </th>
                                <td><input type="text" /></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </b-col>
                    </b-row>

                    <!-- Cout pendant séjour au Bénin  -->
                    <b-row>
                      <b-col md="12" class="mt-2">
                        <b-form-group
                          label-cols-lg="2"
                          label="Combien avez-vous payé en tout pour ce voyage pendant votre séjour à l'étranger ?"
                          label-for="cout_etranger"
                        >
                          <!-- {{sendForm.depensesDurantSejour.type}} -->
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                          >
                            <b-form-radio-group
                              v-model="sendForm.depensesDurantSejour.type"
                              :options="cout_etranger"
                              value-field="id"
                              text-field="name"
                              class="demo-inline-spacing"
                              stacked
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                        <!-- tableau pendant sejour etranger  -->
                        <div
                          class="table-responsive text-left mt-3"
                          v-if="
                            sendForm.depensesDurantSejour.type ==
                            'J\'ai payé pendant mon séjour'
                          "
                        >
                          <table class="table table-striped table-bordered">
                            <thead>
                              <tr>
                                <th scope="col" class="text-left">
                                  Indiquez le montant sur les lignes
                                  correspondantes
                                </th>
                                <th scope="col" class="text-left">
                                  Indiquez la devise dans laquelle vous avez
                                  payé
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th scope="row">
                                  <span>
                                    Transport international
                                    <input
                                      type="number"
                                      min="0"
                                      v-model="
                                        sendForm.depensesDurantSejour.options[0]
                                          .valeur
                                      "
                                    />
                                  </span>
                                </th>
                                <td>
                                  <v-select
                                    v-model="
                                      sendForm.depensesDurantSejour.options[0]
                                        .devise
                                    "
                                    type="text"
                                    :options="devise"
                                    label="title"
                                    placeholder="Devise"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">
                                  <span>
                                    Hébergement
                                    <input
                                      type="number"
                                      min="0"
                                      v-model="
                                        sendForm.depensesDurantSejour.options[1]
                                          .valeur
                                      "
                                    />
                                  </span>
                                </th>
                                <td>
                                  <v-select
                                    v-model="
                                      sendForm.depensesDurantSejour.options[1]
                                        .devise
                                    "
                                    type="text"
                                    :options="devise"
                                    label="title"
                                    placeholder="Devise"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">
                                  <span>
                                    Transport local (train, bus, taxi, etc)
                                    <input
                                      type="number"
                                      min="0"
                                      v-model="
                                        sendForm.depensesDurantSejour.options[2]
                                          .valeur
                                      "
                                    />
                                  </span>
                                </th>
                                <td>
                                  <v-select
                                    v-model="
                                      sendForm.depensesDurantSejour.options[2]
                                        .devise
                                    "
                                    type="text"
                                    :options="devise"
                                    label="title"
                                    placeholder="Devise"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">
                                  <span>
                                    Location de voiture
                                    <input
                                      type="number"
                                      min="0"
                                      v-model="
                                        sendForm.depensesDurantSejour.options[3]
                                          .valeur
                                      "
                                    />
                                  </span>
                                </th>
                                <td>
                                  <v-select
                                    v-model="
                                      sendForm.depensesDurantSejour.options[3]
                                        .devise
                                    "
                                    type="text"
                                    :options="devise"
                                    label="title"
                                    placeholder="Devise"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">
                                  <span>
                                    Carburant automobile
                                    <input
                                      type="number"
                                      min="0"
                                      v-model="
                                        sendForm.depensesDurantSejour.options[4]
                                          .valeur
                                      "
                                    />
                                  </span>
                                </th>
                                <td>
                                  <v-select
                                    v-model="
                                      sendForm.depensesDurantSejour.options[4]
                                        .devise
                                    "
                                    type="text"
                                    :options="devise"
                                    label="title"
                                    placeholder="Devise"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">
                                  <span>
                                    Repas et boisson
                                    <input
                                      type="number"
                                      min="0"
                                      v-model="
                                        sendForm.depensesDurantSejour.options[5]
                                          .valeur
                                      "
                                    />
                                  </span>
                                </th>
                                <td>
                                  <v-select
                                    v-model="
                                      sendForm.depensesDurantSejour.options[5]
                                        .devise
                                    "
                                    type="text"
                                    :options="devise"
                                    label="title"
                                    placeholder="Devise"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">
                                  <span>
                                    Produits culturels
                                    <input
                                      type="number"
                                      min="0"
                                      v-model="
                                        sendForm.depensesDurantSejour.options[6]
                                          .valeur
                                      "
                                    />
                                  </span>
                                </th>
                                <td>
                                  <v-select
                                    v-model="
                                      sendForm.depensesDurantSejour.options[6]
                                        .devise
                                    "
                                    type="text"
                                    :options="devise"
                                    label="title"
                                    placeholder="Devise"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">
                                  <span>
                                    Sport et produit de loisirs
                                    <input
                                      type="number"
                                      min="0"
                                      v-model="
                                        sendForm.depensesDurantSejour.options[7]
                                          .valeur
                                      "
                                    />
                                  </span>
                                </th>
                                <td>
                                  <v-select
                                    v-model="
                                      sendForm.depensesDurantSejour.options[7]
                                        .devise
                                    "
                                    type="text"
                                    :options="devise"
                                    label="title"
                                    placeholder="Devise"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">
                                  <span>
                                    Bien et services liés à la santé
                                    <input
                                      type="number"
                                      min="0"
                                      v-model="
                                        sendForm.depensesDurantSejour.options[8]
                                          .valeur
                                      "
                                    />
                                  </span>
                                </th>
                                <td>
                                  <v-select
                                    v-model="
                                      sendForm.depensesDurantSejour.options[8]
                                        .devise
                                    "
                                    type="text"
                                    :options="devise"
                                    label="title"
                                    placeholder="Devise"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">
                                  <span>
                                    Textiles, vêtements, chaussures et produits
                                    en cuire
                                    <input
                                      type="number"
                                      min="0"
                                      v-model="
                                        sendForm.depensesDurantSejour.options[9]
                                          .valeur
                                      "
                                    />
                                  </span>
                                </th>
                                <td>
                                  <v-select
                                    v-model="
                                      sendForm.depensesDurantSejour.options[9]
                                        .devise
                                    "
                                    type="text"
                                    :options="devise"
                                    label="title"
                                    placeholder="Devise"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">
                                  <span>
                                    Tabac
                                    <input
                                      type="number"
                                      min="0"
                                      v-model="
                                        sendForm.depensesDurantSejour
                                          .options[10].valeur
                                      "
                                    />
                                  </span>
                                </th>
                                <td>
                                  <v-select
                                    v-model="
                                      sendForm.depensesDurantSejour.options[10]
                                        .devise
                                    "
                                    type="text"
                                    :options="devise"
                                    label="title"
                                    placeholder="Devise"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">
                                  <span>
                                    Objets de valeurs
                                    <input
                                      type="number"
                                      min="0"
                                      v-model="
                                        sendForm.depensesDurantSejour
                                          .options[11].valeur
                                      "
                                    />
                                  </span>
                                </th>
                                <td>
                                  <v-select
                                    v-model="
                                      sendForm.depensesDurantSejour.options[11]
                                        .devise
                                    "
                                    type="text"
                                    :options="devise"
                                    label="title"
                                    placeholder="Devise"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">
                                  <span>
                                    Autres
                                    <input
                                      type="number"
                                      min="0"
                                      v-model="
                                        sendForm.depensesDurantSejour
                                          .options[12].valeur
                                      "
                                    />
                                  </span>
                                </th>
                                <td>
                                  <v-select
                                    v-model="
                                      sendForm.depensesDurantSejour.options[12]
                                        .devise
                                    "
                                    type="text"
                                    :options="devise"
                                    label="title"
                                    placeholder="Devise"
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </b-col>
                    </b-row>

                    <!-- Sejour satisfaisant ?  -->
                    <b-row>
                      <b-col md="12" class="mt-2">
                        <b-form-group
                          label-cols-lg="2"
                          label-size="lg"
                          label="Dans l'ensemble, votre séjour à l'étranger vous a-t-il satisfait ?"
                          label-for="sejour_satisfaisant"
                        >
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                          >
                            <b-form-radio-group
                              v-model="sendForm.satisfactionSejour"
                              :options="sejour_satisfaisant"
                              value-field="id"
                              text-field="name"
                              stacked
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <!-- fréquence du voyage  -->
                    <b-row>
                      <b-col md="12" class="mt-2">
                        <b-form-group
                          label-cols-lg="2"
                          label-size="lg"
                          label="Voyagez-vous souvent à l'étranger ?"
                          label-for="souvent_etranger"
                        >
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                          >
                            <b-form-radio-group
                              v-model="sendForm.frequenceVoyage"
                              :options="souvent_etranger"
                              value-field="id"
                              text-field="name"
                              stacked
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <!-- Sexe  -->
                    <b-row>
                      <b-col md="12" class="mt-2">
                        <b-form-group
                          label-cols-lg="2"
                          label="Sexe"
                          label-for="sexe"
                        >
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                          >
                            <b-form-radio-group
                              v-model="sendForm.sexe"
                              :options="sexe"
                              class="demo-inline-spacing"
                              name="radio-sexe"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <!-- Age -->
                    <b-row>
                      <b-col md="12" class="mt-2">
                        <b-form-group
                          label-cols-lg="2"
                          label="Votre âge"
                          label-for="age"
                        >
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model="sendForm.age"
                              placeholder="Age"
                              type="number"
                              min="0"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </div>
                </div>
                <!-- button -->
                <div class="text-right">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    variant="primary"
                    block
                    @click="sendToPreview()"
                  >
                    Aperçu avant enregistrement
                  </b-button>
                </div>
              </span>
            </b-card>
          </div>
        </b-col>
      </b-row>
    </validation-observer>
  </div>
</template>



<script>
import {
  BButton,
  BCard,
  BCardHeader,
  BCardBody,
  BCardImg,
  BCol,
  BRow,
  BFormInput,
  BFormGroup,
  BFormRadioGroup,
  BFormFile,
  BListGroup,
  BListGroupItem,
  BFormCheckbox,
  BFormCheckboxGroup,
  VBPopover,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import { ContentLoader } from "vue-content-loader";
import localstorageService from "@/services/localstorage/localstorage.service";
import TipsModalCreateFromLastRecord from "@/components/TipsModalCreateFromLastRecord.vue";
import utilsService from "@/services/utils/utils.service";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
// import { mapGetters, mapActions } from 'vuex'

// store modules and vuex utilities
import etablissementsStroreModule from "@/store/etablissements";
import paramsStoreModule from "@/store/params";
import utilsStoreModule from "@/store/utils";
import formulaireStoreModule from "@/store/formulaire";

import {
  registerStoreModule,
  unregisterStoreModule,
} from "@/helpers/vuex-utils";

export default {
  setup() {
    const requiredStoreModules = [
      { path: "etablissements", module: etablissementsStroreModule },
      { path: "params", module: paramsStoreModule },
      { path: "utils", module: utilsStoreModule },
      { path: "formulaire", module: formulaireStoreModule },
    ];
    // Register module
    registerStoreModule(requiredStoreModules);

    return { requiredStoreModules };
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules);
  },
  components: {
    BButton,
    BCard,
    BCardHeader,
    BCardBody,
    BCardImg,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BFormRadioGroup,
    BFormFile,
    BListGroup,
    BListGroupItem,
    BFormCheckbox,
    BFormCheckboxGroup,

    flatPickr,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },

  directives: {
    Ripple,
    "b-popover": VBPopover,
  },

  data() {
    return {
      required,
      // Pays de residence
      paysResidence: [],
      // Nationality
      paysVisite: [
        { title: "Benin" },
        { title: "Togolaise" },
        { title: "Nigeria" },
      ],
      // Select Motif_Travel: '',
      motif_travel: [
        {
          id: "Emplois dans une entité résidente dans ce pays",
          name: "Emplois dans une entité résidente dans ce pays",
        },
        {
          id: "Diplomate, agent consulaire ou militaire en service",
          name: "Diplomate, agent consulaire ou militaire en service",
        },
        { id: "Chauffeur professionnel", name: "Chauffeur professionnel" },
        {
          id: "Équipage de navire / personnel aérien",
          name: "Équipage de navire / personnel aérien",
        },
        {
          id: "En transit à l'aéroport (transfert, vol de continuation vers autre pays)",
          name: "En transit à l'aéroport (transfert, vol de continuation vers autre pays)",
        },
        {
          id: "Visite de parents / d/amis",
          name: "Visite de parents / d/amis",
        },
        {
          id: "Vacances, loisirs et détente",
          name: "Vacances, loisirs et détente",
        },
        { id: "Éducation et formations", name: "Éducation et formations" },
        { id: "Santé et soins médicaux", name: "Santé et soins médicaux" },
        { id: "Religion / pèlerinage", name: "Religion / pèlerinage" },
        { id: "Achat", name: "Achat" },
        { id: "Transit", name: "Transit" },
        {
          id: "Voyage d/affaire individuel (par exemple)",
          name: "Voyage d/affaire individuel (par exemple)",
        },
        {
          id: "Voyage d/affaire individuel (par exemple, visite d/un fournisseur ou d/un client)",
          name: "Voyage d/affaire individuel (par exemple, visite d/un fournisseur ou d/un client)",
        },
        {
          id: "Conférence / congrès / séminaire",
          name: "Conférence / congrès / séminaire",
        },
        {
          id: "Assemblée de la société / Réunion d/affaire",
          name: "Assemblée de la société / Réunion d/affaire",
        },
        {
          id: "Foire ou autre manifestation commerciale",
          name: "Foire ou autre manifestation commerciale",
        },
        {
          id: "Voyage de motivation (le voyage est payé par votre société / organisation)",
          name: "Voyage de motivation (le voyage est payé par votre société / organisation)",
        },
        { id: "Autres (A preciser)", name: "Autres motif (A preciser)" },
      ],

      // Activité pratiquée

      activite_pratique: [
        {
          value: "Restaurant / bar / cafés / snack-bars",
          text: "Restaurant / bar / cafés / snack-bars",
        },
        { value: "Achat", text: "Achat" },
        {
          value: "Concert / festival de musique",
          text: "Concert / festival de musique",
        },
        {
          value: "bâtiments / châteaux / monuments",
          text: "bâtiments / châteaux / monuments",
        },
        { value: "Excursions", text: "Excursions" },
        {
          value: "Participatioin à une manifestation culturelle",
          text: "Participatioin à une manifestation culturelle",
        },
        {
          value: "Randonnée / trekking en forêt/ montagne",
          text: "Randonnée / trekking en forêt/ montagne",
        },
        {
          value: "Visite des parcs nationaux",
          text: "Visite des parcs nationaux",
        },
        { value: "Golf", text: "Golf" },
        { value: "Chasse", text: "Chasse" },
        { value: "Manifestaion sportive", text: "Manifestaion sportive" },
        { value: "Théâtre", text: "Théâtre" },
        {
          value:
            "Evènement familial (mariage, funérailles, baptême, première communion, etc.)",
          text: "Evènement familial (mariage, funérailles, baptême, première communion, etc.)",
        },
        { value: "Musées", text: "Musées" },
        { value: "Zoo/parcs d’attraction", text: "Zoo/parcs d’attraction" },
        { value: "Plage", text: "Plage" },
        {
          value: "Formation/conférence/séminaire",
          text: "Formation/conférence/séminaire",
        },
        { value: "Visite de sociétés", text: "Visite de sociétés" },
        {
          value: "Autres (Veuillez préciser)",
          text: "Autres (Veuillez préciser)",
        },
      ],

      // Moyen_transport
      moyen_transport: [
        { id: "Avion", name: "Avion" },
        { id: "Voiture", name: "Voiture" },
        { id: "Autobus", name: "Autobus" },
        { id: "Bâteau", name: "Bâteau" },
        { id: "Barque / Pirogue", name: "Barque / Pirogue" },
        { id: "Motocyclette", name: "Motocyclette" },
        { id: "Bicyclette", name: "Bicyclette" },
        { id: "Autres", name: "Autres" },
      ],

      // si_avion,
      si_avion: [
        { id: "Vol privé", name: "Vol privé" },
        { id: "Vol commercial", name: "Vol commercial" },
      ],
      // Autre motif de voyage
      // autres_motif: [],
      // Autre hebergemnt
      // autres_hebergement: [],
      // Radio Remuneration: '',

      si_autobus: [
        { id: "Compagnie béninoise", name: "Compagnie béninoise" },
        { id: "Compagnie étrangère", name: "Compagnie étrangère" },
      ],

      moyen_transport_utilise: [
        { id: "Avion", name: "Avion" },
        { id: "Voiture personnelle", name: "Voiture personnelle" },
        { id: "Voiture de location", name: "Voiture de location" },
        { id: "Autobus", name: "Autobus" },
        { id: "Transport en commun", name: "Transport en commun" },
        { id: "Taxi", name: "Taxi" },
        { id: "Barque / Pirogue", name: "Barque / Pirogue" },
        { id: "Motocyclette", name: "Motocyclette" },
        { id: "Bicyclette", name: "Bicyclette" },
        {
          id: "Autres moyen de transport.",
          name: "Autres moyen de transport. Veuillez préciser",
        },
      ],

      cout_avant_benin: [
        {
          id: "Je n'ai rien payé avant mon arrivé",
          name: "Je n'ai rien payé avant mon arrivé",
        },
        { id: "Voyage à forfait", name: "Voyage à forfait" },
        { id: "Voyage sans forfait", name: "Voyage sans forfait" },
      ],

      cout_etranger: [
        {
          id: "Je n'ai rien payé pendant mon séjour",
          name: "Je n'ai rien payé pendant mon séjour",
        },
        {
          id: "J'ai payé pendant mon séjour",
          name: "J'ai payé pendant mon séjour",
        },
      ],

      sejour_satisfaisant: [
        { id: "Très mécontent", name: "Très mécontent" },
        { id: "Mécontent", name: "Mécontent" },
        { id: "Ni mécontent, ni content", name: "Ni mécontent, ni content" },
        { id: "Content", name: "Content" },
        { id: "Très content", name: "Très content" },
      ],

      souvent_etranger: [
        { id: "Plusieurs fois par an", name: "Plusieurs fois par an" },
        { id: "Une fois par an", name: "Une fois par an" },
        {
          id: "Tous les deux ans / tous les trois ans",
          name: "Tous les deux ans / tous les trois ans",
        },
        { id: "Moins souvent", name: "Moins souvent" },
        {
          id: "C'est la premiere fois que je viens au Bénin",
          name: "C'est la premiere fois que je viens au Bénin",
        },
      ],

      devise: ["euro £", "dollars $", "fcfa f", "yen", "naira"],

      sexe: [
        { text: "Masculin", value: "Masculin" },
        { text: "Feminin", value: "Feminin" },
      ],

      sendForm: {
        paysResidence: null,
        paysVisites: [],
        nationalite: null,
        dateDepartBenin: null,
        dateRetourtBenin: null,

        coVoyageursAdultes: null,
        coVoyageursEnfants: null,

        motifVoyage: null,
        activiteEtranger: [],
        nombreNuitParType: [
          {
            type: "Hôtel/Pension",
            nombreNuit: null,
          },
          {
            type: "Parents/Amis",
            nombreNuit: null,
          },
          {
            type: "Auberge de jeunesse",
            nombreNuit: null,
          },
          {
            type: "Résidence secondaire",
            nombreNuit: null,
          },
          {
            type: "Maison/Appartement loué",
            nombreNuit: null,
          },
          {
            type: "Chambre d’hôte",
            nombreNuit: null,
          },
          {
            type: null,
            nombreNuit: null,
          },
        ],
        moyenTransportDepartBenin: null,
        departBeninParAvion: {
          type: null,
          compagnie: null,
        },
        departBeninParAutobus: {
          compagnie: null,
          residenceCompagnie: null,
        },
        moyenTransportEtranger: null,
        depensesAvantDepart: {
          type: null,
          coutTotal: null,
          devise: null,
          options: [
            {
              titre: "Transport international",
              valeur: null,
              etat: null,
            },
            {
              titre: "Hébergement",
              valeur: null,
              etat: null,
            },
            {
              titre: "Repas et boissons",
              valeur: null,
              etat: null,
            },
            {
              titre: "Location de voiture",
              valeur: null,
              etat: null,
            },
            {
              titre: "Transport local (train, bus, taxi, etc.)",
              valeur: null,
              etat: null,
            },
            {
              titre: "Activité (loisir/sport/culture, etc)",
              valeur: null,
              etat: null,
            },
            {
              titre: "Autre",
              valeur: null,
              etat: null,
            },
          ],
        },
        depensesDurantSejour: {
          type: null,
          coutTotal: null,
          devise: null,
          options: [
            {
              titre: "Transport international",
              valeur: null,
              devise: null,
            },
            {
              titre: "Hébergement",
              valeur: null,
              devise: null,
            },
            {
              titre: "Transport local (train, bus, taxi, etc.)",
              valeur: null,
              devise: null,
            },
            {
              titre: "Location de voiture",
              valeur: null,
              devise: null,
            },
            {
              titre: "Carburant automibile",
              valeur: null,
              devise: null,
            },
            {
              titre: "Repas et boissons",
              valeur: null,
              devise: null,
            },
            {
              titre: "Produit culturel",
              valeur: null,
              devise: null,
            },
            {
              titre: "Sport et produits de loisirs",
              valeur: null,
              devise: null,
            },
            {
              titre: "Biens et services liés à la santé",
              valeur: null,
              devise: null,
            },
            {
              titre: "Souvenis et cadeaux",
              valeur: null,
              devise: null,
            },
            {
              titre: "Textiles, vêtements, chaussures et produits en cuir",
              valeur: null,
              devise: null,
            },
            {
              titre: "Tabac",
              valeur: null,
              devise: null,
            },
            {
              titre: "Objets de valeurs",
              valeur: null,
              devise: null,
            },
            {
              titre: "Autre",
              valeur: null,
              etat: null,
            },
          ],
        },
        satisfactionSejour: null,
        frequenceVoyage: null,
        sexe: null,
        age: null,

        auteur: null,
      },

      paiementAvantArrive: null,
      paiementDurantSejour: null,

      autresmotifVoyage: null,
      autresActiviteEtranger: null,
      autreTypeHebergement: null,
      autreMoyenTransportBenin: null,
      autreMoyenTransportEtranger: null,
    };
  },

  watch: {
    $route: {
      immediate: true,
      async handler(val, old) {
        const { countries, sexs } = utilsService.currentUserUtils();
        this.paysResidence = countries;
      },
    },
  },

  methods: {
    // ...mapActions('etablissements', { action_findEtablissement: 'findEtablissement' }),
    // getListEtablissement() {
    //   this.action_findEtablissement()
    //     .then(response => {
    //       this.lites_hotels = response.data.resources
    //       console.log('list-etablissement', response, this.lites_hotels)
    //     })
    //     .catch(err => {
    //       // this.isLoadingTableData = false
    //       console.log('error-list-etablissement', err)
    //     })
    // },
    sendToPreview() {
      // this.$emit('onFormSucess', {
      //   routerParams: { preview: true, data: this.sendForm },
      // })
      // return

      this.$refs.formRules.validate().then((success) => {
        if (success) {
          if (this.autresmotifVoyage) this.sendForm.motifVoyage;
          if (this.autresActiviteEtranger) this.sendForm.activiteEtranger;
          if (this.autreTypeHebergement) this.nombreNuitParType[7].nombreNuit;
          if (this.autreMoyenTransportBenin)
            this.sendForm.moyenTransportDepartBenin;
          if (this.autreMoyenTransportEtranger)
            this.sendForm.moyenTransportEtranger;

          // console.log('this.sendForm: ', this.sendForm)
          // const form = this.sendForm
          this.$emit("onFormSucess", {
            routerParams: { preview: true, data: this.sendForm },
          });

          // axios
          //   .post('api/v1/adresses', {
          //   })
          //   .then(response => console.log('response: ', response))
          //   .catch(error => console.log(error))
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Champs requis",
              icon: "TriangleIcon",
              // text: '👋 Chocolate oat cake jelly oat cake candy jelly beans pastry.',
              variant: "danger",
            },
          });
        }
      });

      // this.sendForm = {
      //   npi: null,
      //   dateArrivee: null,
      //   heureArrivee: null,
      //   numeroChambre: null,
      //   dateDepart: null,
      //   lastname: null,
      //   firstname: null,
      //   maidenname: null,
      //   sexe: null,
      //   dateNaissance: null,
      //   lieuNaissance: null,
      //   nationalite: null,
      //   adresse: null,
      //   bp: null,
      //   villeCommune: null,
      //   departement: null,
      //   pays: null,
      //   telephone: null,
      //   email: null,
      //   categorie: null,
      //   autrescategorie: null,
      //   modePrincipalTransport: null,
      //   motifPrincipaleVoyage: null,
      //   autresMotifPrincipalVoyage: null,
      //   question: null,
      //   peiceIdentite: null,
      //   numeroPiece: null,
      //   delivrePar: null,
      //   a: null,
      //   signature: null,
      // }
    },
  },
};
</script>




<!-- <script>
import {
  BCol,
  BRow,
  BButton,
  BCard,
  BCardHeader,
  BCardBody,
  BFormRadioGroup,
  BListGroup,
  BListGroupItem,
  BFormGroup,
  BFormInput,
  BCardImg,
  BInputGroupPrepend,
  BInputGroup,
} from "bootstrap-vue";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import axios from "axios";
import { mapActions } from "vuex";
import vSelect from "vue-select";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
} from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ContentLoader } from "vue-content-loader";
import localstorageService from "@/services/localstorage/localstorage.service";
import TipsModalCreateFromLastRecord from "@/components/TipsModalCreateFromLastRecord.vue";
import utilsService from "@/services/utils/utils.service";


export default {
  components: {
    BButton,
    BCol,
    BRow,
    BCardHeader,
    BCardBody,
    BFormRadioGroup,
    BCard,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BFormInput,
   
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BCardImg,
   
    BInputGroupPrepend,
    BInputGroup,
    ContentLoader,
    TipsModalCreateFromLastRecord,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition], 
  props: {
    etablissementDetails: null,
  },
  data() {
    return {

    };
  },
  computed: {
    
  },
  watch: {
   
  },
  mounted() {
   
  },
  methods: {
    ...mapActions("params", {
      fetchAnnees: "fetchAnnees",
    }),
    ...mapActions("formulaire", {
      getLastFormCreatedByUser: "getLastFormCreatedByUser",
    }),
    ...mapActions("utils", {
      checkIFU: "checkIFU",
    }),
    ...mapActions("params", {
      action_loadDepartments: "loadDepartments",
      action_loadCommunes: "loadCommunes",
      action_loadArrondissements: "loadArrondissements",
      action_loadNeighborhoods: "loadNeighborhoods",
    }),
    ...mapActions("etablissements", {
      createEtablissement: "createEtablissement",
      action_getListTypesEtablissement: "getListTypesEtablissement",
    }),
   
    showModal() {
      this.$refs[this.tipsModalRef].show();
    },
    hideModal() {
      this.$refs[this.tipsModalRef].hide();
    },
    fillFormWithLastRecord() {
      this.isFillingForm = true;
      this.infos = this.lastRecordedForm;
      setTimeout(() => {
        this.isFillingForm = false;
      }, 1000);
      this.hideModal();
    
    },
    sendToPreview() {
      this.$refs.formRules.validate().then(async (success) => {

     
        const { currentUserId } = utilsService.currentUserUtils()

        if (success) {
          let canSend = true;
          
          const form = {
            ...this.infos,
            auteur: currentUserId ? currentUserId : '' 
          };
          if (form.qrcode) delete form.qrcode;
          if (form._id) delete form._id;
          if(this.raisonNonVoyageAutre) form.raisonNonVoyage = this.raisonNonVoyageAutre
          
          
          if (canSend) {
            this.$emit("onFormSucess", {
              routerParams: { preview: true, data: form },
            });
          }
        } else {
          const errorFirst = this.$el.querySelector(".is-invalid");
          // console.log(errorFirst);
          if (errorFirst) {
            errorFirst.scrollIntoView({ behavior: "instant" });
            errorFirst.focus();
          }
        }
      });
    },
  },
};
</script> -->

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
